import { useState, useEffect } from 'react'
import './calculator-page.scss'
import { Spinner } from '@fluentui/react'
import api from '../api-client'
import { Section, RadioToggle, LoanInformation } from '../components'
import { useAppState, useUtmQuery } from '../hooks'
import {
  errorToString,
  dollars,
  dollarsToNum,
  isServiceableAddress,
  StreetAddress,
  ITenant,
  Incentive,
  LoanCalculations,
  Validate,
  IProduct,
  AdderTypes,
  EquipmentType,
  AmortizationSchedule,
  MAX_LOAN_THRESHOLD,
} from '@oneethos/shared'
import { AddressInputs, DollarsInput, NumericInput, SelectYesNo, TextInput } from '../components/forms'
import { FaBuilding, FaHandHoldingHeart, FaHouseChimney } from 'react-icons/fa6'
import { toast } from 'react-toastify'
import Equipments, { IEquipmentCard } from '../components/equipments'
import { getSystemLabels } from '../lib/teslaSystemDetailsLabels'
import LoanSummary from '../components/loan-summary'
import LoanDetails from '../components/loan-details'
import LoanDetailLogos from '../components/loan-detail-logos'

type PaymentCalculatorProps = {
  formData?: any
  isResidential: boolean
  onChange?: (formData: any) => void
  showCalculation?: boolean
  onShowCalculation?: () => void
  financingTenant?: ITenant
  incentive?: Incentive
  loadingCalculation?: boolean
  interestRate?: number
  loanTermMonths?: number
  amortization?: AmortizationSchedule
  amortizationAutoPay?: AmortizationSchedule
  amortizationNoAutoPay?: AmortizationSchedule
  selectedEquipment?: IEquipmentCard | null
  onEquipmentSelect?: (equipment: IEquipmentCard | null) => void
}

type LoanDetailsProps = {
  formData: any
  financingTenant: ITenant
  incentive: Incentive
  rateEffectiveDate: string
  loanPlusClosingCosts: number
  isResidential: boolean
  loanTermMonths: number
  isOrgTeslaCertified: boolean
  selectedEquipment: IEquipmentCard | null
}

const IncentiveLoanDetails = ({
  incentive,
  financingTenant,
  isOrgTeslaCertified,
  selectedEquipment,
  isResidential
}: LoanDetailsProps) => {
  return <div className="incentive-loan-details">
    <LoanDetailLogos
      financingTenant={financingTenant}
      incentive={incentive}
      isOrgTeslaCertified={isOrgTeslaCertified}
      selectedEquipment={selectedEquipment}
      isResidential={isResidential}
    />

    <div className="savings" dangerouslySetInnerHTML={{ __html: incentive.savingsHTML }}></div>
    <div className="inc-descr" dangerouslySetInnerHTML={{ __html: incentive.incentiveDescription }}></div>
    <div className="detail-wrap">
      <div className="loan-detail" dangerouslySetInnerHTML={{ __html: incentive.loanDetailHTML }}></div>
      <div className="loan-tbl" dangerouslySetInnerHTML={{ __html: incentive.loanTableHTML }}></div>
    </div>
  </div>
}

const TotalCostsSummary = ({ systemCost, loanAmount, formData, handleChange }) => {
  return (
    <div className="totals-wrapper">
      <div className="form-group">
        <label><b className='text-dark'>Total System Cost</b></label>
        <TextInput value={systemCost} disabled />
      </div>
      <div className="form-group">
        <label>{AdderTypes.Downpayment}</label>
        <DollarsInput
          placeholder="$0.00"
          emptyOk
          before="-"
          value={formData.adders?.find(a =>
            a.description === AdderTypes.Downpayment)?.amount}
          onChange={v => handleChange({
            ...formData,
            adders: [
              ...(formData?.adders?.filter(a =>
                a.description !== AdderTypes.Downpayment) || []),
              { description: AdderTypes.Downpayment, amount: v }
            ]
          })}
          extraValidator={(value) => {
            const downpaymentAmount = dollarsToNum(value)
            const systemCostAmount = dollarsToNum(formData.systemCost)
            return downpaymentAmount <= systemCostAmount 
              ? '' 
              : "Value can't be higher than total system cost"
          }}
        />
      </div>

      <div className="form-group">
        <label><b className='text-dark'>Requested Loan Amount</b></label>
        <TextInput value={loanAmount} disabled />
      </div>
    </div>
  )
}

const PaymentCalculator = ({
  formData,
  isResidential,
  onChange,
  onShowCalculation,
  financingTenant,
  incentive,
  loadingCalculation,
  interestRate,
  loanTermMonths,
  amortizationAutoPay,
  amortizationNoAutoPay,
  onEquipmentSelect
}: PaymentCalculatorProps) => {
  const {
    registration: { installer },
    tenant: { config }
  } = useAppState()

  const { rateEffectiveDate, products } = config
  const [hasTouchedSystemSize, setHasTouchedSystemSize] = useState(false)
  const [shouldShowValidation, setShouldShowValidation] = useState(false)
  const [showAllEquipment, setShowAllEquipment] = useState(false)

  const addressIsValid = StreetAddress.isValid(formData.installationAddress)
  const solarCostIsValid = formData.solarCost && formData.solarCost !== '$0.00'
  const systemSizeIsValid = formData.systemSizeKW > 0
  const downpaymentIsValid = !formData.adders?.some(adder =>
    adder.description === AdderTypes.Downpayment &&
    dollarsToNum(adder.amount) >= dollarsToNum(formData.systemCost))

  const minimumAmount = isResidential ? 10000 : 25000
  const solarCostNumeric = dollarsToNum(formData.solarCost)
  const amountSolarPriceIsValid = solarCostNumeric >= minimumAmount
  const loanAmountIsValid = dollarsToNum(formData.loanAmount) >= minimumAmount

  const validationMessage = [
    { isValid: addressIsValid, message: 'Please provide a valid Address.', value: formData.installationAddress },
    { isValid: solarCostIsValid, message: 'Please provide a valid Solar Price.', value: formData.solarCost },
    { isValid: systemSizeIsValid, message: 'Please provide a valid System Size.', value: formData.systemSizeKW },
    { isValid: loanAmountIsValid, message: `Loan amount should be at least ${dollars(minimumAmount)}.`, value: formData.loanAmount },
  ]
  
  const isOrgTeslaCertified = installer?.org.isTeslaCertified
  const [selectedEquipment, setSelectedEquipment] = useState<IEquipmentCard | null>( isResidential 
    && isOrgTeslaCertified ? {
      id: EquipmentType.TeslaRoof,
      image: '/images/tesla-roof.png',
      title: 'Tesla Roof + Powerwall 3',
      description: 'New luxury integrated solar roof with home battery backup',
      isTesla: true
    } : null)

  const systemLabels = getSystemLabels(selectedEquipment, isResidential, isOrgTeslaCertified)
  const equipmentTypeForFormData = isResidential && isOrgTeslaCertified 
  ? selectedEquipment?.id : EquipmentType.StandardSolar

  const enableCalculationButton = (
    addressIsValid &&
    solarCostIsValid &&
    systemSizeIsValid &&
    amountSolarPriceIsValid &&
    downpaymentIsValid &&
    loanAmountIsValid
  )

  useEffect(() => {
    if (formData.installationAddress && formData.solarCost && formData.systemSizeKW) {
      setShouldShowValidation(true)
    } else {
      setShouldShowValidation(false)
    }
  }, [formData.installationAddress, formData.solarCost, formData.systemSizeKW])

  useEffect(() => {
    onEquipmentSelect?.(selectedEquipment)
    handleChange({
      ...formData,
      equipmentType: equipmentTypeForFormData
    })

  }, [selectedEquipment, onEquipmentSelect])

  const handleChange = (nextData) => {
    const size = parseFloat(nextData.systemSizeKW) || 0
    const solar = dollarsToNum(nextData.solarCost || '') || 0

    const ppw = size && solar ? dollars(solar / (size * 1000)) : ''
    const validAdders = Validate.getValidAdders(nextData.adders)
    const adders = Validate.normalizeAdders(validAdders)
    const systemCost = dollars(LoanCalculations.calcSystemCost(solar, adders))
    const loanAmount = dollars(LoanCalculations.calcLoanAmount(solar, adders))

    onChange({
      ...nextData,
      systemCost: systemCost,
      loanAmount: loanAmount,
      ppWForPV: ppw,
      equipmentType: equipmentTypeForFormData
    })
  }

  const product = products.find(p => p.productType === formData?.projectType)
  const { state, country } = (formData.installationAddress || {})
  const { systemCost, loanAmount } = formData
  const storagePrice = formData.adders?.find(a => a.description === AdderTypes.StoragePrice)?.amount

  const loanAmountNumeric = dollarsToNum(loanAmount)
  const fees = LoanCalculations.estimatedClosingCosts(
    loanAmountNumeric,
    state, 
    formData.projectType, 
    incentive
  )
  const loanPlusClosingCosts = loanAmountNumeric && loanAmountNumeric + fees

  const loanDetailsProps = {
    formData,
    financingTenant,
    incentive,
    product,
    rateEffectiveDate,
    loanPlusClosingCosts,
    isResidential,
    loanTermMonths,
    isOrgTeslaCertified,
    selectedEquipment, 
    amortizationAutoPay,
    amortizationNoAutoPay
  }

  const LoanDetailsComponent = incentive?.financingPartner ? IncentiveLoanDetails : LoanDetails

  return <Section>
    <div className="section-title">
      <h2 className="margin-bottom-0">Loan Monthly Payment Calculator</h2>
    </div>
    <div className="form-group">
      <label className="my-2 mb-3">Select Project Type</label>
      <RadioToggle
        value={formData.projectType}
        onChange={v => handleChange({ ...formData, projectType: v })}
        options={[
          { label: 'Residential', value: 'residential', Icon: FaHouseChimney },
          { label: 'Commercial', value: 'commercial', Icon: FaBuilding },
          { label: 'Non-Profit', value: 'non-profit', Icon: FaHandHoldingHeart },
        ]}
      />
    </div>

    {!formData.projectType ? null : <>
      {isResidential ? null : <div className="form-group">
        <label>Business or Non-Profit Name</label>
        <TextInput
          value={formData.businessName}
          onChange={val => handleChange({ ...formData, businessName: val })}
        />
      </div>}

      <AddressInputs
        address={formData.installationAddress || {}}
        label="Address where solar panels will be installed"
        onChange={address => handleChange({
          ...formData,
          installationAddress: address,
        })}
      />

      {
        (
          isResidential &&
          StreetAddress.isValid(formData.installationAddress) &&
          !isServiceableAddress(state, country, installer.org)
        ) ? (
          <div className="alert alert-danger">
            <b>Warning:</b> the consumer will be unlikely to complete the application for this
            address as it is outside the supported area. For more information, please contact
            your account representative.
          </div>
        ) : null
      }
      {isResidential && isOrgTeslaCertified ?
        <div>
          {showAllEquipment ? <label className="mb-2">Select an equipment type</label> : null}
          <Equipments onChange={setSelectedEquipment} showAll={showAllEquipment} />
          <button
            className='btn btn-outline-primary mb-3 show-more-btn'
            onClick={() => setShowAllEquipment(true)}
            style={{ display: showAllEquipment ? 'none' : 'block' }}
          >Show more equipment types</button>
        </div>
        : null}
      <h4>System Details</h4>
      <div className="system-details">
        <div className="system-prices">
          <div className="form-group">
            <label>{systemLabels.solarLabel}</label>
            <DollarsInput
              placeholder="$10,000"
              extraValidator={(value) => { 
                const solarAmount = dollarsToNum(value)
                return solarAmount >= minimumAmount ? '' : `The minimum solar price is ${dollars(minimumAmount)}`
              }}
              value={formData.solarCost}
              onChange={v => handleChange({
                ...formData,
                solarCost: v
              })}
            />
          </div>
          {systemLabels.roofInput ?
            <div className="form-group">
              <label>{AdderTypes.RoofPrice}</label>
              <DollarsInput
                placeholder="$0.00"
                emptyOk
                before="+"
                value={formData.adders?.find(a =>
                  a.description === AdderTypes.RoofPrice)?.amount}
                onChange={v => handleChange({
                  ...formData,
                  adders: [
                    ...(formData.adders?.filter(a =>
                      a.description !== AdderTypes.RoofPrice) || []),
                    { description: AdderTypes.RoofPrice, amount: v }
                  ]
                })}
              />
            </div> : null
          }
          <div className='adders-container'>
            <div className="form-group">
              <label>{systemLabels.storageLabel}</label>
              <DollarsInput
                placeholder="$0.00"
                emptyOk
                before="+"
                value={storagePrice}
                onChange={v => handleChange({
                  ...formData,
                  adders: [
                    ...(formData.adders?.filter(a =>
                      a.description !== AdderTypes.StoragePrice) || []),
                    {
                      description: AdderTypes.StoragePrice,
                      amount: v
                    }
                  ]
                })}
                extraValidator={(value) => {
                  const storageAmount = dollarsToNum(value)
                  if (storageAmount === 0) return ''
                  
                  if (selectedEquipment?.isTesla && isResidential) {
                    return formData.teslaPowerwallQty > 0 
                      ? '' 
                      : 'Powerwall Quantity is required'
                  }
                  
                  return ''
                }}
              />
            </div>
            {systemLabels.powerwallQtyInput ?
              <div className="form-group">
                <label>Powerwall Quantity</label>
                <NumericInput
                  emptyOk
                  placeholder="0"
                  value={formData.teslaPowerwallQty}
                  onChange={v => {
                    handleChange({
                      ...formData,
                      teslaPowerwallQty: v
                    })
                  }}
                  extraValidator={(value) => {
                    const numValue = parseInt(value) || 0
                    const storageAmount = dollarsToNum(formData.adders?.find(a => 
                      a.description === AdderTypes.StoragePrice)?.amount)
                    
                    if (numValue === 0) {
                      return storageAmount === 0 ? '' : 'Powerwall Quantity is required'
                    }
                    
                    return storageAmount > 0 ? '' : 'Powerwall Total Price is required'
                  }}
                />
              </div> : null
            }
            {systemLabels.hvacInput ?
              <div className="form-group">
                <label>{AdderTypes.HvacPrice}</label>
                <DollarsInput
                  placeholder="$0.00"
                  emptyOk
                  value={formData.adders?.find(a => a.description === AdderTypes.HvacPrice)?.amount}
                  before="+"
                  onChange={v => handleChange({
                    ...formData,
                    adders: [
                      ...(formData.adders?.filter(a =>
                        a.description !== AdderTypes.HvacPrice) || []),
                      { description: AdderTypes.HvacPrice, amount: v }
                    ]
                  })}
                />
              </div> : null
            }
            <div className="form-group">
              <label>{AdderTypes.Other} <sup>†</sup></label>
              <DollarsInput
                placeholder="$0.00"
                emptyOk
                value={formData.adders?.find(a => a.description === AdderTypes.Other)?.amount}
                before="+"
                onChange={v => handleChange({
                  ...formData,
                  adders: [
                    ...(formData.adders?.filter(a =>
                      a.description !== AdderTypes.Other) || []),
                    { description: AdderTypes.Other, amount: v }
                  ]
                })}
              />
            </div>
          </div>
          <TotalCostsSummary
            systemCost={systemCost}
            loanAmount={loanAmount}
            formData={formData}
            handleChange={handleChange}
          />

        </div>
        <div className="system-size">
          <div className="form-group">
            <label>System Size (kW)</label>
            <NumericInput
              value={formData.systemSizeKW}
              onBlur={() => setHasTouchedSystemSize(true)}
              onChange={v => handleChange({
                ...formData,
                systemSizeKW: v
              })}
            />
          </div>
          <div className="form-group">
            <label>Price Per Watt</label>
            <TextInput
              disabled
              value={formData.ppWForPV}
            />
          </div>
          {systemLabels.otherContent}
          <div className="mobile-totals">
            <TotalCostsSummary
              systemCost={systemCost}
              loanAmount={loanAmount}
              formData={formData}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
      {(!selectedEquipment?.isTesla) &&
        hasTouchedSystemSize &&
        formData.ppWForPV &&
        dollarsToNum(formData.ppWForPV) > 3.75 ? (
        <div className="alert alert-danger">
          Loan applications with PPW higher than $3.75 are unlikely to be approved.
        </div>
      ) : null}
      {financingTenant ? <div className="fin-tenant">
        <LoanDetailsComponent {...loanDetailsProps} />
      </div> : <div style={{ textAlign: 'center' }}>
        {loadingCalculation ? <Spinner /> : <>
          <div>
            {shouldShowValidation && validationMessage.map((error, index) => (
              error.value && !error.isValid && (
                <div key={index} className="alert alert-info">
                  <div>{error.message}</div>
                </div>
              )
            ))}
          </div>
          <button
            type="button"
            className="btn btn-primary"
            disabled={!enableCalculationButton}
            onClick={() => onShowCalculation()}
          >Calculate Payment</button>
        </>}
      </div>}
    </>}
  </Section >
}

const SendApplicationLink = ({
  formData,
  isResidential,
  incentive,
  selectedEquipment,
  amortization
}: PaymentCalculatorProps) => {
  const [email, setEmail] = useState('')
  // const [installWillCompleteIn30Days, setinstallWillCompleteIn30Days] = useState('')
  const [sending, setSending] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [referralId, setReferralId] = useState('')

  const { tenant: { config } } = useAppState()
  const { products } = config

  const product = products?.find(p => p.productType === formData?.projectType)

  const utm = useUtmQuery()
  const { systemCost, loanAmount } = formData

  const submitReferral = () => {
    setError('')
    setSuccess('')

    if (Validate.isValidEmail(email) !== "") {
      setError('Please enter a valid email address')
      return
    } else if (!systemCost) {
      setError('Please enter a valid loan amount')
      return
    }

    const validAdders = Validate.getValidAdders(formData.adders)
    const adders = Validate.normalizeAdders(validAdders)

    setSending(true)
    api.post('/loanapps', {
      ...utm,
      email,
      productId: product?._id || product?.id,
      projectType: formData.projectType,
      businessName: formData.businessName || '',
      incentive,
      installationAddress: formData.installationAddress,
      userAddress: formData.installationAddress,
      solarCost: dollarsToNum(formData.solarCost),
      systemSizeKW: parseFloat(formData.systemSizeKW),
      systemCost: dollarsToNum(formData.systemCost),
      loanAmount: dollarsToNum(formData.loanAmount),
      ppWForPV: dollarsToNum(formData.ppWForPV),
      teslaPowerwallQty: formData.teslaPowerwallQty,
      adders: adders,
      equipmentType: formData.equipmentType,
      // installWillCompleteIn30Days,  
    }).then(res => {
      if (res.message) {
        setSuccess(res.message)
      } else {
        setSuccess("Thank you for submitting your request. An email confirmation will be sent to your customer shortly.")
      }

      if (res._id) {
        setReferralId(res._id)
      }
    }).catch(ex => {
      setSuccess('')
      setError(errorToString(ex))
    }).finally(() => setSending(false))
  }

  if (success) {
    return <div>
      <div className="alert alert-success">{success}</div>

      {referralId ? <div>
        <h3>Has the customer already signed a contract?</h3>
        <p>Visit the project page to upload the signed contract and avoid delays</p>
        <div className="buttons">
          <a className="btn btn-primary" href={`/project/${referralId}?v=checklist`}>View Project Page</a>
        </div>
      </div> : null}
    </div>
  }

  return <div>
    <h2 className="margin-bottom-medium">Send Application Link </h2>
    <LoanSummary
      formData={formData}
      selectedEquipment={selectedEquipment}
      incentive={incentive}
      amortization={amortization}
    />
    <div className="w-form">
      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get">
        {!selectedEquipment?.isTesla &&
          dollarsToNum(loanAmount) >= MAX_LOAN_THRESHOLD &&
          isResidential ?
          <div className="alert alert-info mt-3">
            Loans larger than {dollars(MAX_LOAN_THRESHOLD)} will
            require additional income verification.
          </div> : null
        }
        {/* <div className="form-group mt-4">
          <label>Will this project be installed in the next 30 days?</label>
          <SelectYesNo 
            value={formData.installWillCompleteIn30Days}
            onChange={ev => setinstallWillCompleteIn30Days(ev)}
          />
        </div> */}
        <div className="form-group mt-4">
          <label>Enter customer email address</label>
          <input type="email"
            className="form-control"
            maxLength={256}
            name="email"
            data-name="email"
            placeholder="client@email.com"
            id="email"
            autoComplete="off"
            value={email}
            onChange={ev => setEmail(ev.target.value)}
          />
        </div>
        <div className="buttons form-group">
          {sending ? <Spinner /> : <button
            // disabled={!installWillCompleteIn30Days}
            type="button"
            className="btn btn-primary"
            onClick={submitReferral}
          >Send Referral Link</button>}
        </div>
        {error ? <div className="alert alert-danger">{error}</div> : null}
      </form>
    </div>
  </div >
}

export const CalculatorPage = () => {
  const [formData, setFormData] = useState<any>({ systemCost: '0', loanAmount: '0' })
  const [showCalculation, setShowCalculation] = useState<boolean>(false)
  const [financingTenant, setFinancingTenant] = useState(null)
  const [incentive, setIncentive] = useState()
  const [loadingCalculation, setLoadingCalculation] = useState(false)
  const [currentEquipment, setCurrentEquipment] = useState<IEquipmentCard | null>(null)
  const [amortAutoPay, setAmortAutoPay] = useState<AmortizationSchedule | null>(null)
  const [amortNoAutoPay, setAmortNoAutoPay] = useState<AmortizationSchedule | null>(null)
  const { tenant: { config } } = useAppState()

  const product = config.products?.find((product: IProduct) =>
    product.productType === formData.projectType)

  const interestRate = product?.interestRateWithAutopay
  const loanTermMonths = product?.term

  const isResidential = !['commercial', 'non-profit'].includes(formData.projectType)

  return <div className="calculator-page">
    <PaymentCalculator
      formData={formData}
      financingTenant={financingTenant}
      isResidential={isResidential}
      onChange={data => {
        // change in address or system price requires recalculation
        setFinancingTenant(null)
        setShowCalculation(false)
        setIncentive(undefined)
        setFormData({
          ...data,
          loanTermMonths,
        })
      }}
      showCalculation={showCalculation}
      incentive={incentive}
      loadingCalculation={loadingCalculation}
      amortizationAutoPay={amortAutoPay}
      amortizationNoAutoPay={amortNoAutoPay}
      onShowCalculation={() => {
        const query = new URLSearchParams({
          ...formData.installationAddress,
          projectType: formData.projectType,
          loanAmount: dollarsToNum(formData.loanAmount),
          interestRate,
          loanTermMonths,
          equipmentType: formData.equipmentType,
        })

        setLoadingCalculation(true)
        api.get(`/tenant/for-project?${query.toString()}`).then(res => {
          const closingCosts = LoanCalculations.estimatedClosingCosts(
            dollarsToNum(formData.loanAmount),
            formData.installationAddress?.state,
            formData.projectType
          )

          const getAmortization = rate => LoanCalculations.getPaymentAndAmortizationSchedule(
            loanTermMonths,
            dollarsToNum(formData.loanAmount),
            closingCosts,
            rate,
            product?.interestOnlyPeriod
          )

          setAmortAutoPay(getAmortization(product.interestRateWithAutopay))
          setAmortNoAutoPay(getAmortization(product.interestRateWithoutAutopay))

          if (parseFloat(res.incentive?.estPriceWithIncentives) > 0 && isResidential) {
            setIncentive(res.incentive)
          }

          if (res.tenant) {
            setFinancingTenant(res.tenant)
          }

          setShowCalculation(true)
        }).catch(ex => toast.error(errorToString(ex)))
          .finally(() => setLoadingCalculation(false))
      }}
      onEquipmentSelect={setCurrentEquipment}
    />
    {showCalculation ? <>
      {incentive ? null :
        <LoanInformation
          loanTermYears={LoanCalculations.calculateLoanTermInYears(loanTermMonths)}
          isResidential={isResidential}
          interestRateWithAutopay={product?.interestRateWithAutopay}
          interestRateWithoutAutopay={product?.interestRateWithoutAutopay}
        />}
      <Section>
        <SendApplicationLink
          formData={formData}
          isResidential={isResidential}
          incentive={incentive}
          selectedEquipment={currentEquipment}
          amortization={amortAutoPay}
        />
      </Section>
    </> : null}
  </div>
}

export default CalculatorPage
