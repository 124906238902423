import { useState } from 'react'
import { dollars, errorToString, StatusData } from '@oneethos/shared'
import Amortization from './amortization'
import LoanSummary from './loan-summary'
import { useAppState } from '../hooks'
import { Link } from './link'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { dateAndTime } from '@oneethos/shared'
import { toast } from 'react-toastify'
import api from '../api-client'
import { Spinner } from '@fluentui/react'

type ProjectLoanDetailsProps = {
  project: StatusData
  onChange: (project: StatusData) => void
}

export const ProjectLoanDetails = ({ project, onChange }: ProjectLoanDetailsProps) => {
  const { tenant } = useAppState()
  const [showAmortization, setShowAmortization] = useState(false)
  const [drafting, setDrafting] = useState(false)

  // TODO: need a better indicator of whether this is a native project for this env
  const hasInfo = project.appId && project.productId

  return <div className="row">
    {!hasInfo ? <div className="alert alert-warning mx-3">
      This project either hasn't been submitted or may have originated from
      another environment which means it may not have all details available.
    </div> : null}
    <div className="col-4">
      <h6>Cost Summary</h6>
      <LoanSummary
        formData={{
          ...project,
          loanAmount: dollars(project.loanAmount),
        }}
        incentive={project.incentive}
      />
    </div>
    <div className="col-4">
      <h6>Payment Details</h6>
      {showAmortization
        ? <>
          <Amortization statusData={project} />
          <div className="alert alert-danger">
            This calculation assumes a Loan Date of today and may
            not match dates from original documents.
          </div>
        </>
        : <button type="button"
          className="btn btn-primary btn-sm"
          onClick={() => setShowAmortization(true)}>
          Calculate Payment Details
        </button>
      }
    </div>
    <div className="col-4">
      <h6>Docusign Envelopes</h6>
      {project.envelopes?.length ? project.envelopes?.map((e, i) => <div key={i}>
        <Link href={`${tenant.config.docusign?.appUrl}/send/documents/details/${e.envelopeId}`} target="_blank">
          {dateAndTime(new Date(e.timestamp))}
          <FaExternalLinkAlt className="ms-2" />
        </Link>
      </div>) : <div className="alert alert-secondary text-small py-2">no envelopes found</div>}

      {project.productId ? (
        drafting ? <Spinner /> : <button type="button"
          className="btn btn-primary btn-sm mt-2"
          onClick={ev => {
            ev.preventDefault()
            const dt = project.updatedDate?.toLocaleString()
            if (!window.confirm(`To prevent errors, please make sure this project has synced the latest changes from Airtable before proceeding. This record was last updated at ${dt}.\n\nContinue to draft loan docs?`)) {
              return Promise.resolve()
            }

            setDrafting(true)
            return api.post(`/loanapps/${project.id}/envelopes`)
              .then(res => {
                toast.success('Draft Loan Docs Created!')
                onChange(new StatusData(res))
              })
              .catch(ex => { toast.error(errorToString(ex)) })
              .finally(() => setDrafting(false))
          }}
        >
          Draft New Loan Docs
        </button>
      ) : <div className="alert alert-light text-small">Not eligible for new loan docs</div>}
    </div>


  </div>
}

export default ProjectLoanDetails
