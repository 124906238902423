import React from 'react'
import { AdderTypes, dollars, LoanCalculations, dollarsToNum, Validate, Incentive, Adders, AmortizationSchedule } from '@oneethos/shared'
import { IEquipmentCard } from './equipments'

type LoanSummaryProps = {
  formData: any
  selectedEquipment?: IEquipmentCard
  incentive?: Incentive
  showApr?: boolean
  amortization?: AmortizationSchedule
}

const EstimatedApr = ({ formData, amortization }: LoanSummaryProps) => {
  const { docStamps } = LoanCalculations.estimatedClosingCostsDetail(
    dollarsToNum(formData.loanAmount),
    formData.installationAddress?.state,
    formData.projectType
  )

  const loanTermMonths = formData.loanTermMonths || formData.loanDurationMonths

  const estimatedApr = LoanCalculations.apr(
    dollarsToNum(formData.loanAmount) + (docStamps || 0),
    loanTermMonths,
    amortization?.payment
  )

  return <tr className="mt-3">
    <td className='fw-bold py-1'>Estimated APR**:</td>
    <td className="text-end fw-bold py-1">{estimatedApr.toFixed(2)}%</td>
  </tr>
}

export const LoanSummary = ({
  formData,
  selectedEquipment,
  incentive,
  showApr = false,
  amortization
}: LoanSummaryProps) => {

  const closingCosts = (
    incentive?.loanFees
      ? parseFloat(incentive.loanFees)
      : LoanCalculations.estimatedClosingCosts(
        dollarsToNum(formData.loanAmount),
        formData.installationAddress?.state,
        formData.projectType
      ))

  const loanTermMonths = formData.loanTermMonths || formData.loanDurationMonths
  const amt = incentive?.amountFinanced || formData.loanAmount
  const amountFinanced = dollarsToNum(amt) + closingCosts
  const loanDurationYears = LoanCalculations.calculateLoanTermInYears(loanTermMonths)
  const adders = Validate.getValidAdders(formData.adders)
  const equipmentType = formData.equipmentType || selectedEquipment?.id
  const nonDownPaymentAdders = Adders.getNonDownPaymentAdders(adders, equipmentType)

  const downpaymentAdder = adders
    ?.find(adder => adder.description === AdderTypes.Downpayment)

  return (
    <>
      <table className="table table-borderless w-auto mb-1">
        <tbody>
          <tr>
            <td className="py-1">Solar Price:</td>
            <td className="text-end py-1">{dollars(formData.solarCost)}</td>
          </tr>
          {nonDownPaymentAdders?.map((adder, index) => (
            <tr key={index}>
              <td className="py-1">{adder.description}:</td>
              <td className="text-end py-1">
                {adder.amount}
              </td>
            </tr>
          ))}
          <tr className="border-top">
            <td className="py-1">Total System Cost:</td>
            <td className="text-end py-1">{dollars(formData.systemCost)}</td>
          </tr>
          <tr>
            <td className="py-1">Estimated Loan Fees*:</td>
            <td className="text-end py-1">{dollars(closingCosts)}</td>
          </tr>
          {
            downpaymentAdder && (
              <tr>
                <td className="py-1">Downpayment:</td>
                <td className="text-end py-1">- {dollars(downpaymentAdder?.amount)}</td>
              </tr>
            )
          }
          <tr className="border-top">
            <td className='fw-bold py-1'>Total Amount Financed:</td>
            <td className="text-end fw-bold py-1">{dollars(amountFinanced)}</td>
          </tr>
          {showApr && !incentive ?
            <EstimatedApr formData={formData} amortization={amortization} />
            : null}
        </tbody>
      </table>

      <div className="text-small mt-3 fst-italic">
        *This is the estimate of the loan fees associated with processing your loan. This amount is
        rolled into the loan to be financed over the {loanDurationYears}-year term.
      </div>
      {showApr ?
        <a 
          href="https://www.oneethos.com/apr-explained" 
          className="text-small d-flex mb-3 fst-italic"
          target="_blank"
        >**How is the APR Calculated?</a> : null
      }
    </>
  )
}

export default LoanSummary
