

export const TEST_APPLICATION_PREFILL = {
  firstName: 'John',
  lastName: 'Doe',
  phone: process.env.REACT_APP_PREFILL_PHONE || '',
  statedGrossAnnualIncome: '$200,000.00',
  installedAtDifferentLocation: 'yes',
  userAddress: {
    streetAddress: '123 Main St',
    city: 'Anytown',
    state: 'FL',
    zip: '12345',
    country: 'United States'
  },
  preapprovalLetter: { agreement: { agrees: true, browser: { userAgent: 'test' }, timestamp: new Date() } },
  agreesToProceedWithHardCreditInquiry: { agrees: true, browser: { userAgent: 'test' }, timestamp: new Date() },
  ssn: '666-22-3333',
  driversLicenseNo: '101010101',
  driversLicenseIssuingState: 'FL',
  driversLicenseIssueDate: '10/10/2020',
  driversLicenseExpirationDate: '10/10/2025',
  birthDate: '10/10/1990',
  citizenship: 1,
  hasMortgage: 'yes',
  employerName: 'OneEthos',
  occupation: 'Prefilled',
  yearsAtEmployer: 3,
  propertyHeldInTrust: 'no',
  includeCoBorrower: 'no',
  previousEmployment: 'Prefilled',
  race: 'White',
  statedHouseholdIncome: '$200,000.00',
  useAutoDebit: 'no',
  incomeVerificationDocuments: [
    {
      containerName: "tenant-cfb-docs",
      blobName: "3d0c1de7-0e26-43bd-8182-f9496e5320c9__1pct for planet.jpeg",
      label: "_1pct for planet.jpeg",
      contentType: "image/jpeg",
      docType: "W-2",
    }
  ],
}

export const TEST_APPLICATION_PREFILL_COBORROWER = {
  includeCoBorrower: 'yes',
  coFirstName: 'Jane',
  coLastName: 'Doe',
  coBorrowerAddress: {
    streetAddress: '123 Main St',
    city: 'Anytown',
    state: 'FL',
    zip: '12345',
    country: 'United States'
  },
  coPhone: process.env.REACT_APP_PREFILL_PHONE || '',
  coEmail: process.env.REACT_APP_PREFILL_EMAIL || 'anon+coprefill@oneethos.com',
  coStatedGrossAnnualIncome: '$200,000.00',
  coSsn: '666-33-4444',
  coDriversLicenseNo: '202020202',
  coDriversLicenseIssuingState: 'FL',
  coBirthDate: '10/10/1990',
  coCitizenship: 1,
  coHasMortgage: 'yes',
  coEmployerName: 'OneEthos',
  coOccupation: 'Prefilled',
  coYearsAtEmployer: 3,
  coDriversLicenseIssueDate: '10/10/2020',
  coDriversLicenseExpirationDate: '10/10/2025',
  coRace: 'White',
  coStatedHouseholdIncome: '$200,000.00',
  coIncomeVerificationDocuments: [
    {
      containerName: "tenant-cfb-docs",
      blobName: "3d0c1de7-0e26-43bd-8182-f9496e5320c9__1pct for planet.jpeg",
      label: "_1pct for planet.jpeg",
      contentType: "image/jpeg",
      docType: "W-2",
    }
  ],
}
