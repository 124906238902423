import { ConsumerApplicationIntegration, errorToString, InstallerRegistrationPayload, StatusData, TEST_APP_ID } from '@oneethos/shared'
import { toast } from 'react-toastify'
import { GrTest } from 'react-icons/gr'
import api from '../api-client'
import ActionLink from './action-link'
import { Link } from './link'
import { useAppState } from '../hooks'
import { useState } from 'react'
import { Spinner } from '@fluentui/react'

type ProjectActionsProps = {
  project: StatusData
  installer: InstallerRegistrationPayload
  onChange: (project: StatusData) => void
}

export const ProjectActions = ({ project, installer, onChange }: ProjectActionsProps) => {
  const { tenant } = useAppState()
  const backend = tenant.config.consumerApplicationIntegration
  const [syncing, setSyncing] = useState(false)
  const [drafting, setDrafting] = useState(false)

  return (
    <div className="project-actions d-flex flex-column align-items-end">
      {project.installersStatus === 'Awaiting Application' ? <>
        <ActionLink
          label="Re-send referral email"
          action={() => api.post(`/loanapps/${project.id}/resend-referral`)}
          doneLabel="Resent referral email"
        />
      </> : null}

      {installer.isSupport
        ? <>
          <ActionLink
            label='Send New Referral Link'
            action={() => api.post(`/loanapps/${project.id}/send-new-referral`)}
            doneLabel='Sent New Referral Link'
          />
          <Link
            label={`Find Prequal Reports`}
            href={`/prequal-reports?loanAppId=${project.id}`}
          />
          {project.projectType === 'residential' ? (
            <Link label={`View application`} href={`/apply/${project.lookup}`} />
          ) : null}
          {(
            project.appId &&
            project.appId !== TEST_APP_ID &&
            backend === ConsumerApplicationIntegration.DecisionPro
          ) ? (
            syncing ? <Spinner /> : <Link
              label={`Sync from Airtable`}
              href='#'
              onClick={ev => {
                ev.preventDefault()
                setSyncing(true)
                return api.get(`/loanapps/${project.lookup}/sync`).then(res => {
                  toast.success('synced from airtable')
                  onChange(new StatusData(res))
                }).catch(ex => toast.error(ex.error || ex.message, { autoClose: false }))
                  .finally(() => setSyncing(false))
              }}
            />
          ) : null}

          {process.env.REACT_APP_NODE_ENV === 'test' ? <>
            {/* <Link
              label={<>NACHA</>}
              href='#'
              onClick={() => api.get(`/loanapps/${project.id}/nacha`).catch(console.error)}
            >NACHA</Link> */}
            <ActionLink
              label={<>Send Loan Docs <GrTest title="Test Only" /></>}
              variant="secondary"
              action={() => {
                return api.post(`/loanapps/${project.id}/envelopes`, { send: true })
                  .then(() => { /* noop */ })
                  .catch(ex => { toast.error(errorToString(ex)) })
              }}
              doneLabel="Loan Docs Sent!"
            />
            <ActionLink
              label={<>Send Test Notification <GrTest title="Test Only" /></>}
              variant="secondary"
              action={() => api.post(`/loanapps/${project.id}/test-notification`)}
              doneLabel="Test Notification(s) Sent"
            />
          </> : null}
        </> : null}
    </div>
  )
}

export default ProjectActions
