import { useState, useEffect } from "react"
import { Section } from "../components"
import { useAppState } from "../hooks"
import { Link, Navigate } from "react-router-dom"
import api from '../api-client'
import { FaList, FaUsersCog } from "react-icons/fa"
import { AddOrg } from "../components/add-org"
import './installerOrgsPage.scss'

export const InstallerOrgsPage = () => {
  const [error, setError] = useState('')
  const [orgs, setOrgs] = useState([])

  const { registration: { installer } } = useAppState()

  const hasAccess = ['super_admin', 'support', 'integrator'].includes(installer.access)

  useEffect(() => {
    if (hasAccess) {
      api.get('/orgs').then(res => {
        res.orgs.sort((a, b) => b.count - a.count)
        setOrgs(res.orgs)
      }).catch(ex => { setError(ex.error || ex.message) })
    }
  }, [hasAccess])

  if (!hasAccess) {
    return <Navigate to="/projects" />
  }

  return <div className="installer-admin-page">
    {installer.isSupport ? <Section>
      <AddOrg />
    </Section> : null}

    <Section>
      <h2>Installer Companies</h2>
      {error ? <div className="alert alert-danger">{error}</div> : null}

      <div className="table-list-wrap">
        <table className="table">
          <tbody>
            <tr>
              <th></th>
              <th style={{ textAlign: 'center' }}>Integrations</th>
              <th>Contacts</th>
              <th></th>
            </tr>
            {orgs.map((co, i) => <tr key={i}>
              <td>
                <div className="d-flex align-items-center">
                  <div>
                    <div className="">{co.companyName}</div>
                    <div className="installer-id text-muted small">{co.installerID}</div>
                  </div>
                  {co.isIntegrator ? <div className="ms-2 badge rounded-pill text-bg-warning">
                    Integrator
                  </div> : null}
                </div>
              </td>
              <td style={{ textAlign: 'right' }}>
                {co.enabledIntegrations?.map(int => <div key={int} className="ms-1 badge rounded-pill text-bg-secondary">
                  {int}
                </div>)}
              </td>
              <td>{co.count}</td>
              <td>
                <Link to={`/projects/${co.installerID}`} style={{ padding: '0.3em' }}><FaList size={20} /></Link>
                &nbsp;
                <Link to={`/installer-admin/${co.installerID}`}><FaUsersCog size={20} /></Link>
              </td>
            </tr>)}
          </tbody>
        </table>
      </div>
    </Section>
  </div>
}

export default InstallerOrgsPage