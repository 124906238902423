import { dollars, StatusData } from "@oneethos/shared"
import { LoanCalculations } from "@oneethos/shared"


type AmortizationProps = {
  statusData: StatusData
}

const Amortization = ({ statusData }: AmortizationProps) => {
  try {
    const { docStamps, fees } = LoanCalculations.estimatedClosingCostsDetail(
      statusData.loanAmount,
      statusData.installationAddress?.state,
      statusData.projectType
    )

    const amortization = LoanCalculations.getPaymentAndAmortizationSchedule(
      statusData.loanDurationMonths,
      statusData.loanAmount,
      docStamps + fees,
      statusData.interest,
      statusData.interestOnlyMonths
    )

    const estimatedApr = LoanCalculations.apr(
      statusData.loanAmount + (docStamps || 0),
      statusData.loanDurationMonths,
      amortization.payment
    )

    return <div>
      {/* <h3>Amortization</h3> */}
      <table className="table" style={{ display: 'inline' }}>
        <thead></thead>
        <tbody>
          <tr>
            <td>Loan Date</td>
            <td>{amortization.loanDate?.toLocaleDateString()}</td>
          </tr>
          <tr>
            <td>Monthly Payment Amount</td>
            <td>{dollars(amortization.payment)}</td>
          </tr>
          <tr>
            <td>First Statement Date</td>
            <td>{amortization.firstPaymentDate?.toLocaleDateString()}</td>
          </tr>
          <tr>
            <td>Final Payment Date</td>
            <td>{amortization.finalPaymentDate?.toLocaleDateString()}</td>
          </tr>
          <tr>
            <td>Finance Charge</td>
            <td>{dollars(amortization.financeCharge)}</td>
          </tr>
          <tr>
            <td>Sum of Total Payments</td>
            <td>{dollars(amortization.sumOfTotalPayments)}</td>
          </tr>
          <tr>
            <td>Estimated APR</td>
            <td>{estimatedApr.toFixed(3)}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  } catch (error) {
    return <div className="alert alert-warning">Cannot calculate payment schedule: {error.message}</div>
  }
}

export default Amortization
