import { YesNoEmpty } from "@oneethos/shared"

type SelectYesNoProps = {
  value: YesNoEmpty
  onChange: (value: YesNoEmpty) => void
}

export const SelectYesNo = ({ value, onChange }: SelectYesNoProps) => {
  return (
    <select
      className="form-select"
      value={value}
      onChange={ev => onChange(ev.target.value as YesNoEmpty)}
    >
      <option value=''></option>
      <option value='yes'>yes</option>
      <option value='no'>no</option>
    </select>
  )
}

