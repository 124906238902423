import React from "react"
import { Spinner } from "@fluentui/react"
import { errorToString } from "@oneethos/shared"
import { useState } from "react"
import { toast } from "react-toastify"
import { Link } from "."

type ActionLinkProps = {
  label: string | React.ReactNode
  action: () => Promise<void>
  doneLabel: string
  children?: React.ReactNode
  variant?: 'primary' | 'secondary'
}

export const ActionLink = ({
  label,
  action,
  doneLabel,
  variant = 'primary'
}: ActionLinkProps) => {
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  if (loading) {
    return <Spinner />
  }

  return <div className="action-link">
    {done ? <div className="badge text-bg-secondary">{doneLabel}</div> : <>
      <Link
        href='#'
        label={label}
        variant={variant}
        onClick={ev => {
          ev.preventDefault()
          setLoading(true)
          action().then(() => {
            setDone(true)
          }).catch(ex => toast.error(errorToString(ex)))
            .finally(() => setLoading(false))
        }}
      />
    </>}
  </div>
}

export default ActionLink
