import { useState } from "react"
import { EquifaxCollection, yyyymmdd } from "@oneethos/shared"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"
import { CodedDescriptor } from "./bankruptcies"

type CollectionsProps = {
  collections: EquifaxCollection[]
}
  
const Collections = ({ collections }: CollectionsProps) => {
  const [expand, setExpand] = useState(false)

  if (collections.length === 0) {
    return (
      <div className="fs-5 mb-2">
        <div>Collections (0)</div>
      </div>
    )
  }

  return (
    <div>
      <div className="section-hdr" onClick={() => setExpand(!expand)}>
        Collections ({collections.length})
        {expand ? <FaChevronDown size='15'/> : <FaChevronRight size='15'/>}
      </div>
  
      {expand ? <table className="table">
        <thead>
          <tr>
            <th>Delinquency</th>
            <th>Balance</th>
            <th>Orig Amt</th>
            <th>Customer no</th>
            <th>Classification</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {collections.map((c, i) => <tr key={i} title={JSON.stringify(c, null, ' ')}>
            <td>{yyyymmdd(c.dateOfFirstDelinquency)}</td>
            <td>{c.balance}</td>
            <td>{c.originalAmount}</td>
            <td>{c.customerNumber}</td>
            <td><CodedDescriptor {...c.creditorClassificationCode} /></td>
            <td><CodedDescriptor {...c.statusCode} /></td>
          </tr>)}
        </tbody>
      </table> : null}
    </div>
  )
}

export default Collections