import { Section } from './section'

type LoanInformationProps = {
  loanTermYears: string
  isResidential: boolean
  interestRateWithAutopay: number
  interestRateWithoutAutopay: number
}

export const LoanInformation = ({ 
  loanTermYears, 
  isResidential,
  interestRateWithAutopay,
  interestRateWithoutAutopay 
}: LoanInformationProps) => {
  return (
    <Section>
      <div className="section-title">
        <h2 className="margin-bottom-0">Loan Information</h2>
      </div>
      <ul className="list fw-semibold fs-5 text">
        <li>
          Customers can save up to between 15-30% on project cost
          as OneEthos does not charge dealer fees.
        </li>
        {isResidential ? <>
          <li>Customers can benefit from a 30% tax credit (if applicable). They can choose to
            apply the credit to towards the loan, bringing the monthly payment down.</li>
          <li>Customers' monthly payment will never increase.</li>
          <li>{loanTermYears}-year loan term.</li>
          <li>
            Customers benefit from a {interestRateWithAutopay}% interest rate
            with auto pay, or {interestRateWithoutAutopay}% without auto pay.
          </li>
        </> : <>
          <li>
            Clients can keep any tax credit. They can choose to apply
            the credit to towards the loan, bringing the monthly payment down.
          </li>
          <li>{loanTermYears} loan term.</li>
        </>}
      </ul>
    </Section>
  )
}

export default LoanInformation