export const MAX_LOAN_THRESHOLD = 350000

// Fund Realease 
export const FUND_RELEASE_SUPPORT_LINK = "https://oneethos.com/support/fund-release"
export const FUND_RELEASE_LOAN_HELP_LINK = "https://www.climatefirstbank.com/solar-loan-funding-help"
export const TEST_APP_ID = 9_999_999
export const FUNDS_RELEASE_EXPIRES_SECONDS = 48 * 60 * 60 // 48 hours in seconds

export const FUND_RELEASE_TYPES = [
  "Roof Funding Release",
  "Initial Funding Release",
  "Final Funding Release",
  "Full Funding Release"
]

export const COMMUNITY_PROPERTY_STATES = [
  "AZ",
  "CA",
  "ID",
  "LA",
  "NV",
  "NM",
  "TX",
  "WA",
  "WI",
]