import { mongo } from "mongoose"
import { ApplicantRace } from "./applicant-race"
import { CitizenshipValue } from "./citizenship"
import { SLoanApplication } from "./loan-application"
import { StreetAddress } from "./street-address"
import { YesNoEmpty } from "./types"

export class Applicant {
  consumerId?: string | typeof mongo.ObjectId
  firstName?: string
  middleName?: string
  lastName?: string
  ssn?: string
  phone?: string
  email?: string
  birthDate?: string
  spouseFirstName?: string
  spouseLastName?: string
  spouseEmail?: string
  hasSpouse?: YesNoEmpty
  driversLicenseNo?: string
  driversLicenseIssueDate?: string
  driversLicenseIssuingState?: string
  driversLicenseExpirationDate?: string
  citizenship?: CitizenshipValue
  race?: ApplicantRace
  hasMortgage?: 'yes' | 'no'
  statedHouseholdIncome?: string

  employerName?: string
  occupation?: string
  yearsAtEmployer?: number
  previousEmployment?: string
  statedGrossAnnualIncome?: string

  // special
  streetAddress?: StreetAddress

  constructor(init: Partial<Applicant>) {
    Object.assign(this, init)
  }

  fullName(): string {
    return [this.firstName, this.middleName, this.lastName].filter(Boolean).join(' ').toUpperCase() || 'N/A'
  }

  getNameAndAddress(): string {
    return [
      this.fullName(),
      this.streetAddress ? StreetAddress.toString(this.streetAddress) : ''
    ].filter(Boolean).join(', ')
  }

  getAddress(): string {
    return StreetAddress.toString(this.streetAddress)
  }

  getStreetAndUnit(): string {
    return StreetAddress.streetAndUnit(this.streetAddress)
  }

  cityStateZip(): string {
    return StreetAddress.cityStZip(this.streetAddress)
  }

  static fromLoanApp(lapp: Partial<SLoanApplication>): Applicant {
    let init = {
      streetAddress: lapp.userAddress,
      consumerId: lapp.consumerId
    }

    init = APPLICANT_FIELDS.reduce((acc, field) => {
      acc[field] = lapp[field]
      return acc
    }, init)

    return new Applicant(init)
  }

  static fromLoanAppCoBorrower(lapp: SLoanApplication): Applicant | undefined {
    if (lapp.includeCoBorrower !== 'yes') {
      return
    }

    let init = {
      streetAddress: lapp.coBorrowerAddress,
      consumerId: lapp.consumerId // TODO: maybe consider separate consumerId for co-borrower
    }

    init = APPLICANT_FIELDS.reduce((acc, field) => {
      const key = `co${field[0].toUpperCase()}${field.substring(1)}`
      acc[field] = lapp[key]
      return acc
    }, init)

    return new Applicant(init)
  }
}

const APPLICANT_FIELDS: Array<keyof Applicant> = [
  // intentionally does not include address fields

  'firstName',
  'middleName',
  'lastName',
  'ssn',
  'phone',
  'email',
  'birthDate',
  'driversLicenseNo',
  'driversLicenseIssueDate',
  'driversLicenseIssuingState',
  'driversLicenseExpirationDate',
  'citizenship',
  'race',
  'hasMortgage',
  'statedHouseholdIncome',
  'employerName',
  'occupation',
  'yearsAtEmployer',
  'previousEmployment',
  'statedGrossAnnualIncome'
]
