import { FormInput, SubformProps } from "."
import { AddressInputs } from '../../components'
import { StreetAddress, Validate, isServiceableAddress } from '@oneethos/shared'
import { ReviewTerms } from './review-terms'
import LoanSummary from '../../components/loan-summary'

const { checkDollarValue } = Validate

export const InitialLoanRequest = ({ formData, org, update, save, saving }: SubformProps) => {
  const { state, country } = (formData.installationAddress || {})

  const enableSave = (
    formData.loanAmount &&
    formData.agreesToTermsAndConditions &&
    checkDollarValue(formData.avgMonthlyUtilityBill) === ''
  )

  return <div>
    <h2>Solar Loan Application</h2>
    <div className="alert alert-info">
      Please review the following information carefully. If anything appears incorrect, please
      contact your installer to request a new referral before proceeding with your loan application.
    </div>
    <LoanSummary formData={formData} incentive={formData.incentive} />
    <AddressInputs
      address={formData.installationAddress || {}}
      label="Address where solar panels will be installed"
      onChange={address => {
        update({
          ...formData,
          installationAddress: address,
          userAddress: address
        })
      }}
    />
    {state ? <>
      {!StreetAddress.isValid(formData.installationAddress) ? <div>
        <div className="alert alert-info">
          Please confirm you have entered a complete address.
        </div>
      </div> : <>
        {!isServiceableAddress(state, country, org) ? <div className="alert alert-warning">
          We're sorry, we do not yet support projects at your address.
        </div> : <>
          <FormInput
            field='avgMonthlyUtilityBill'
            value={formData.avgMonthlyUtilityBill}
            onChange={s => update('avgMonthlyUtilityBill', s)}
          />
          <ReviewTerms
            formData={formData}
            update={update}
            save={save}
            saving={saving}
            enableSave={enableSave}
            onAfterSave={save}
          />
          {!enableSave ? (
            <div className = "alert alert-secondary">
            Please confirm address, review and agree to terms, and provide average
            monthly utility bill to proceed to the next step.
          </div>
          ): null}
      </>}
    </>}
  </> : null
}
  </div >
}

export default InitialLoanRequest