import React from 'react'
import { FormInput } from "."

export const EmploymentForm = ({ employment, onChange }) => {
  return <>
    <FormInput
      field='employerName'
      value={employment.employerName}
      onChange={s => onChange('employerName', s)}
    />
    <FormInput
      field='occupation'
      value={employment.occupation}
      onChange={s => onChange('occupation', s)}
    />
    <FormInput
      field='statedGrossAnnualIncome'
      value={employment.statedGrossAnnualIncome}
      onChange={s => onChange('statedGrossAnnualIncome', s)}
    />
    <div className="form-group">
      <label>Time employed at current employer (years)</label>
      <input
        className="form-control"
        type="number"
        onWheel={e => (e.target as HTMLElement).blur()}
        value={employment.yearsAtEmployer || ''}
        onChange={ev => onChange('yearsAtEmployer', ev.target.value)}
      />
    </div>
    <div className="form-group">
      <label>If less than 2 years, list previous employers</label>
      <textarea
        className="form-control"
        value={employment.previousEmployment || ''}
        onChange={ev => onChange('previousEmployment', ev.target.value)}
      />
    </div>
  </>
}

export default EmploymentForm