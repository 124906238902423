const TradeHistoryGrid = ({ trade }) => {
  if (!trade.dateReported) return <tr><td>NO LAST PAYMENT DATE</td></tr>
  
  const maxYear = trade.dateReported.getFullYear()
  const startMonth = trade.dateReported.getMonth()
  const history = [...trade.paymentHistory1to24]
  history.splice(12, 1)
  return (
    <div className="trade-history-grid">
      <div className="year">
        <div className="months m-hdr">
          <div className="year-label"></div>
          <div>J</div> <div>F</div> <div>M</div> <div>A</div>
          <div>M</div> <div>J</div> <div>J</div> <div>A</div>
          <div>S</div> <div>O</div> <div>N</div> <div>D</div>
        </div>
      </div>
      {[maxYear, maxYear - 1, maxYear - 2].map(year => <div className="year" key={year}>
        <div className="months">
          {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map(month => {
            if (year === maxYear) {
              return <div key={month}>{month > startMonth ? '' : history[startMonth - month].code}</div>
            } else if (year === maxYear - 1) {
              return <div key={month}>{history[12 + startMonth - month].code}</div>
            } else {
              const idx = 24 + startMonth - month
              return <div key={month}>{idx < history.length ? history[idx].code : ''}</div>
            }
          })}
          <div className="year-label">{year}</div>
        </div>
      </div>)}
    </div>
  )
}

export default TradeHistoryGrid