import { EquipmentType } from "@oneethos/shared"
import { IEquipmentCard } from "../components/equipments"
import React from "react"

type SystemLabels = {
  solarLabel: string
  storageLabel: string
  roofInput: boolean
  hvacInput: boolean
  powerwallQtyInput: boolean
  otherContent: React.ReactNode
}

const defaultLabels: SystemLabels = {
  solarLabel: 'Solar Price',
  storageLabel: 'Storage Price',
  roofInput: true,
  hvacInput: true,
  powerwallQtyInput: false,
  otherContent: (
    <div className="border rounded pt-3 ps-2 pe-2 mb-3">
      <sup>†</sup> <i>Other refers to items on Climate First Bank's approved list:</i>
      <ul className='pt-2'>
        <li>Electric vehicle chargers</li>
        <li>Spray foam</li>
        <li>Tree trimmings/removals</li>
        <li>Ground mounts/trenching</li>
        <li>Solar pergolas/carports</li>
        <li>Critter guards</li>
        <li>Other enabling electrical upgrades</li>
      </ul>
    </div>
  )
}
  
export const getSystemLabels = (
  selectedEquipment: IEquipmentCard | null, isResidential: boolean, 
  isOrgTeslaCertified: boolean
): 
  SystemLabels => {

  if (!isResidential) {
    return defaultLabels
  }

  if (!isOrgTeslaCertified) {
    return defaultLabels
  }

  switch(selectedEquipment?.id) {
    case EquipmentType.TeslaRoof:
      return {
        solarLabel: 'Tesla Solar Roof',
        storageLabel: 'Powerwall Total Price',
        roofInput: false, 
        hvacInput: false,
        powerwallQtyInput: true,
        otherContent: (
          <div className="border rounded pt-3 ps-2 pe-2 mb-3">
            <sup>†</sup> <i>Other refers to items on Climate First Bank's approved list:</i>
            <ul className='pt-2'>
              <li>Electric vehicle chargers</li>
              <li>Tree trimmings/removals</li>
              <li>Other electrical enabling upgrades</li>
            </ul>
          </div>
        )
      }
    case EquipmentType.TeslaSolar:
      return {
        solarLabel: 'Tesla Solar Panels',
        storageLabel: 'Powerwall Total Price',
        roofInput: true,
        hvacInput: false,
        powerwallQtyInput: true,
        otherContent: (
          <div className="border rounded pt-3 ps-2 pe-2 mb-3">
            * Other refers to items on Climate First Bank's approved list:
            <ul className='pt-2'>
              <li>Electric vehicle chargers</li>
              <li>Tree trimmings/removals</li>
              <li>Other electrical enabling upgrades</li>
            </ul>
          </div>
        )
      }
    default:
      return defaultLabels
  }
}
