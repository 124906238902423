import { MustClickLink } from "../../components/must-click-link"
import { useAppState } from "../../hooks"
import * as Sentry from "@sentry/react"
import api from '../../api-client'
import { SubformProps } from "."
import { Spinner } from '@fluentui/react'
import { useState } from "react"
import { Audience, TEST_APPLICATION_PREFILL, TEST_APPLICATION_PREFILL_COBORROWER } from "@oneethos/shared"
import { getBrowserFingerprintData } from "../../browser-fingerprint"
import { AgreementType } from "@oneethos/shared"
import { toast } from "react-toastify"

type ReviewTermsProps = Partial<SubformProps> & {
  enableSave: boolean
  onAfterSave?: (...any) => void
}

export const ReviewTerms = ({
  formData,
  update,
  saving,
  enableSave,
  onAfterSave
}: ReviewTermsProps) => {
  const { tenant: { config } } = useAppState()

  // the UI prevents them from accepting the terms without clicking the links,
  // so we can safely assume that if they're returning to this page and previously 
  // agreed to terms, they've clicked the links
  const agrees = formData.agreesToTermsAndConditions?.agrees

  const [hasClickedTerms, setHasClickedTerms] = useState(agrees)
  const [hasClickedPrivacy, setHasClickedPrivacy] = useState(agrees)
  const [hasClickedEsign, setHasClickedEsign] = useState(agrees)
  const [savingAgreements, setSavingAgreements] = useState(false)

  const hasClickedAll = hasClickedTerms && hasClickedPrivacy && hasClickedEsign

  const {
    termsAndConditionsUrl = "https://uploads-ssl.webflow.com/635ae89b651fdf01ef0360a7/63fd1bb51695e86a2e73a542_FINAL-Terms-and-Conditions-Climate-First_Accessible.pdf",
    privacyPolicyUrl = "https://uploads-ssl.webflow.com/635ae89b651fdf01ef0360a7/63fd1b125c945e62586bc929_FINAL-Privacy-Policy-Accessible.pdf",
    esignAgreementUrl = "https://uploads-ssl.webflow.com/635ae89b651fdf01ef0360a7/63fd34e2f8e1cd8416c96c95_6251ac8b953d1e2a1dfee967_eSign.pdf"
  } = config

  const saveAgreements = async () => {
    setSavingAgreements(true)
    const browser = getBrowserFingerprintData()

    try {
      // ensure all consents are recorded before proceeding
      await Promise.all([
        { url: esignAgreementUrl, type: AgreementType.EsignAgreement },
        { url: privacyPolicyUrl, type: AgreementType.PrivacyPolicy },
        { url: termsAndConditionsUrl, type: AgreementType.ConsumerTerms }
      ].map(agreement => api.post('/consents/accept', {
        browser,
        agrees: true,
        agreementUrl: agreement.url,
        agreementType: agreement.type,
        audience: Audience.Consumer,
        loanApplicationId: formData._id
      })))
      setSavingAgreements(false)
    } catch (ex) {
      setSavingAgreements(false)
      toast.error('Error recording agreements, please try again. If the problem persists, please contact support.')
      Sentry.captureException(ex, {
        tags: {
          error: 'Error recording agreements'
        }
      })
    }
  }

  return <div className="review-terms">
    <p> Please review our&nbsp;
      <MustClickLink
        url={esignAgreementUrl}
        clicked={hasClickedEsign}
        label="eSign Agreement"
        onClicked={() => {
          setHasClickedEsign(true)
        }}
      />,
      &nbsp;
      <MustClickLink
        url={privacyPolicyUrl}
        clicked={hasClickedPrivacy}
        label="privacy policy"
        onClicked={() => {
          setHasClickedPrivacy(true)
        }}
      />,
      &nbsp;and&nbsp;
      <MustClickLink
        url={termsAndConditionsUrl}
        clicked={hasClickedTerms}
        label="terms and conditions"
        onClicked={() => {
          setHasClickedTerms(true)
        }}
      />
      &nbsp;
      <br />
      <br />
      <b>IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT</b>
      <br />
      To help the government fight the funding of terrorism and money laundering
      activities, Federal law requires all financial institutions to obtain, verify
      and record information that on personal accounts identifies each person who
      opens an account. In addition, on legal entity accounts, we will require
      identification on beneficial owners and controlling person. What this means
      for you: When you open an account, we will ask for your name, address, date of
      birth, and other information that will allow us to identify you. We may also
      ask to see your driver’s license or other identifying documents.
    </p>
    <div className="form-group" style={{ cursor: 'pointer' }}>
      <label>
        <input type="checkbox"
          checked={formData.agreesToTermsAndConditions?.agrees || false}
          disabled={!hasClickedAll}
          onChange={ev => update('agreesToTermsAndConditions', ev.target.checked ? {
            browser: getBrowserFingerprintData(),
            timestamp: new Date(),
            agrees: true
          } : false)}
        />
        &nbsp;
        <b>I have read and agree with eSign Agreement,
          privacy policy, and terms and conditions.</b>
      </label>
    </div>

    {enableSave ? <div className="buttons d-flex justify-content-center">
      <button type="button"
        className="btn btn-primary"
        disabled={saving}
        onClick={async () => {
          await saveAgreements()
          onAfterSave?.()
        }}
      >{saving || savingAgreements ? <Spinner /> : 'Continue'}</button>
      {process.env.REACT_APP_NODE_ENV === 'test' ? <div>
        <button type="button"
          className="btn btn-secondary"
          disabled={saving}
          onClick={async () => {
            await saveAgreements()
            onAfterSave?.({
              advance: false,
              data: {
                ...formData,
                preSubmitStatus: 'Submit Application',
                ...TEST_APPLICATION_PREFILL
              }
            })
          }}
        >Prefill single applicant</button>
        <button type="button"
          className="btn btn-secondary"
          disabled={saving}
          onClick={async () => {
            await saveAgreements()
            onAfterSave?.({
              advance: false,
              data: {
                ...formData,
                preSubmitStatus: 'Submit Application',
                ...TEST_APPLICATION_PREFILL,
                ...TEST_APPLICATION_PREFILL_COBORROWER
              }
            })
          }}
        >Prefill co-applicant</button>
      </div> : null}
    </div> : null}
  </div>
}