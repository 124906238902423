import { DocusignConfig } from "@oneethos/shared"
import { TextInput } from "../forms"

type DocusignFormProps = {
  config: DocusignConfig
  setDocusignConfig: (c: Partial<DocusignConfig>) => void
}

export const DocusignForm = ({ config, setDocusignConfig }: DocusignFormProps) => {
  return <div>
    <h4>Docusign</h4>
    <div className="form-group">
      <label>Docusign Host (domain only)</label>
      <TextInput
        value={config?.host || ''}
        onChange={val => setDocusignConfig({ host: val })}
      />
    </div>

    <div className="form-group">
      <label>Docusign App URL (with protocol)</label>
      <TextInput
        value={config?.appUrl || ''}
        onChange={val => setDocusignConfig({ appUrl: val })}
      />
    </div>

    <div className="form-group">
      <label>Docusign API Account ID</label>
      <TextInput
        value={config?.apiAccountId || ''}
        onChange={val => setDocusignConfig({ apiAccountId: val })}
      />
    </div>

    <div className="form-group">
      <label>Docusign Integration Key</label>
      <TextInput
        value={config?.integrationKey || ''}
        onChange={val => setDocusignConfig({ integrationKey: val })}
      />
    </div>

    <div className="form-group">
      <label>Docusign User ID</label>
      <TextInput
        value={config?.userId || ''}
        onChange={val => setDocusignConfig({ userId: val })}
      />
    </div>

    <div className="form-group">
      <label>Docusign Loan Docs Template ID</label>
      <TextInput
        value={config?.loanDocsTemplateId || ''}
        onChange={val => setDocusignConfig({ loanDocsTemplateId: val })}
      />
    </div>

    <div className="form-group">
      <label>Docusign RSA Key Name</label>
      <TextInput
        value={config?.rsaKeyName || ''}
        onChange={val => setDocusignConfig({ rsaKeyName: val })}
      />
    </div>
  </div>
}