import React from 'react'
import TradeHistoryGrid from './trade-history-grid'
import { dollars, mmyyyy } from '@oneethos/shared'

const AccountReport = ({ trade }) => {
  const leftColumn = [
    { label: 'Account Type:', value: trade.accountTypeCode?.description || '' },
    { label: 'Account Owner:', value: trade.accountDesignator?.description || '' },
    { label: 'Rate/Status:', value: trade.rate?.description || '' },
    { label: 'Date Opened:', value: mmyyyy(trade.dateOpened) || '' },
    { label: 'Date Reported:', value: mmyyyy(trade.dateReported) || '' },
    { label: 'Last Payment Date:', value: mmyyyy(trade.lastPaymentDate) || '' },
  ]

  const rightColumn = [
    { label: 'Balance:', value: dollars(trade.balance || 0, 0) },
    { label: 'Credit Limit:', value: dollars(trade.creditLimit || 0, 0) },
    { label: 'High Credit:', value: dollars(trade.highCredit || 0, 0) },
    { label: 'Scheduled Payment:', value: dollars(trade.scheduledPaymentAmount || 0, 0) },
    { label: 'Actual Payment:', value: dollars(trade.actualPaymentAmount || 0, 0) },
    { label: 'Last Payment Date:', value: mmyyyy(trade.lastPaymentDate) }

  ]

  const getPortfolioInfo = (rawValue: string | undefined) => {
    const type = rawValue?.split(" ")[0]?.toLowerCase() || ''

    switch (type) {
      case 'mortgage':
        return { class: 'mortgage', text: 'Mortgage' }
      case 'revolving':
        return { class: 'revolving', text: 'Revolving' }
      case 'line-of-credit':
      case 'line':
        return { class: 'line-of-credit', text: 'Line of Credit' }
      case 'installment':
        return { class: 'installment', text: 'Installment' }
      default:
        return { class: 'other', text: 'Other' }
    }
  }

  const portfolioInfo = getPortfolioInfo(trade.portfolioTypeCode?.description)

  const delinquency = trade.thirtyDayCounter ||
    trade.ninetyDayCounter ||
    trade.sixtyDayCounter

  const latePaymentsTable = <div className="table-responsive mt-4">
    <table className="table table-bordered text-center" style={{ width: 'auto', float: 'right' }}>
      <thead>
        <tr className="table-light">
          <th className='fw-light'>30</th>
          <th className='fw-light'>60</th>
          <th className='fw-light'>90</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{trade.thirtyDayCounter}</td>
          <td>{trade.sixtyDayCounter}</td>
          <td>{trade.ninetyDayCounter}</td>
        </tr>
      </tbody>
    </table>
  </div>

  return (
    <div className={`account-report`}>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div className={portfolioInfo.class}>{portfolioInfo.text}</div>
        <div className='text-end'>
          <div className='fw-bold'>Account Number</div>
          <div>{trade.accountNumber}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-3">
          {leftColumn.map((row) => (
            <div key={row.label} className="d-flex justify-content-between">
              <span>{row.label}</span> <span className='text-muted'>{row.value}</span>
            </div>
          ))}
          {trade.paymentHistory1to24 && (
            <div className='mb-3'>
              <div className='my-2'>History:</div>
              <TradeHistoryGrid trade={trade} />
            </div>
          )}
        </div>
        <div className="col-md-6">
          {rightColumn.map((row) => (
            <div key={row.label} className="d-flex justify-content-between">
              <span>{row.label}</span> <span className='text-muted'>{row.value}</span>
            </div>
          ))}
          {delinquency ? latePaymentsTable : null}
        </div>
      </div>
    </div>
  )
}

export default AccountReport

