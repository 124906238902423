import React from 'react'

type LinkProps = {
  href: string
  label?: string | React.ReactNode
  target?: string
  variant?: 'primary' | 'secondary'
  title?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  onClick?: (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const Link = ({
  href,
  label,
  variant,
  style,
  target,
  title,
  onClick,
  children
}: LinkProps) => {
  const className = `link link-wrap`

  return <div className={className} style={style}>
    <a title={title} href={href}
      target={target}
      className={variant ? `link-${variant}` : ''}
      onClick={onClick}>{label || children}</a>
  </div>
}