import { Incentive } from "."
import { daysBetween } from "./date-utils"
import { ProjectType } from "./loan-application"
import { AdderTypes, Adder } from "./adders"

type PaymentScheduleItem = {
  date: Date
  daysInPeriod: number
  interest: number
  nonCompoundedInterest: number
  paymentAmount: number
  outstandingPrincipal: number
}

export type AmortizationSchedule = {
  schedule: PaymentScheduleItem[]
  payment: number
  sumOfTotalPayments: number
  loanDate: Date
  loanAmount: number
  firstPeriodDate: Date
  firstPaymentDate: Date
  finalPaymentDate: Date
  financeCharge: number
}

export class LoanCalculations {
  static estimatedClosingCosts(
    amt: number,
    state: string,
    projectType: ProjectType,
    incentive?: Incentive,
  ): number {
    if (incentive?.loanFees !== undefined) {
      return parseFloat(incentive.loanFees)
    }
    const detail = this.estimatedClosingCostsDetail(amt, state, projectType)
    return Object.values(detail).reduce((acc, val) => acc + val, 0)
  }

  static estimatedClosingCostsDetail(
    amt: number,
    state: string,
    projectType: ProjectType
  ): { fees: number, docStamps?: number } {
    if (['commercial', 'non-profit'].includes(projectType)) {
      return { fees: amt * 0.015 }
    } else {
      const fees = 1125
      const calcDocStamps = amount => Math.ceil(amount / 100) * 0.35
      // have to calculate doc stamps on the doc stamps; test case method
      const initialDocStamps = state === 'FL' ? calcDocStamps(amt + fees) : 0
      const totalDocStamps = (state === 'FL' ? calcDocStamps(initialDocStamps) : 0) + initialDocStamps

      // spreadsheet method which rounds instead of ceilings the 2nd calc
      // const docStampsOnDocStamps = (Math.round(initialDocStamps / 10) / 10 * 0.35)
      // const totalDocStamps = (state === 'FL' ? initialDocStamps + docStampsOnDocStamps : 0)
      return { fees, docStamps: totalDocStamps }
    }
  }

  // Function to find the first statement date
  static firstStatementDate(loanDate: Date): Date {
    const date = new Date(loanDate)
    const newDay = date.getDate()
    const newMonth = date.getMonth()

    // Initialize variables for the adjusted month and day
    let adjustedMonth, adjustedDay

    if (newDay < 20) {
      adjustedMonth = newMonth + 1 // Move to next month
      adjustedDay = 16 // Set day to 16
    } else {
      adjustedMonth = newMonth + 2
      adjustedDay = 5
    }

    // rollovers for date/month happen automatically
    date.setDate(adjustedDay)
    date.setMonth(adjustedMonth)

    return date
  }

  // NOTE: should this be calculated based on the schedule instead???
  static finalPaymentDate(loanDate: Date, loanDurationMonths: number): Date {
    const dt = new Date(loanDate)
    dt.setMonth(dt.getMonth() + loanDurationMonths)
    return dt
  }

  // Function to calculate the fixed monthly payment amount using amortization formula
  static estimatedFixedPayment(
    principal: number,
    annualInterestRate: number,
    totalPeriods: number
  ): number {

    if (annualInterestRate === 0) {
      return principal / totalPeriods
    }

    const monthlyInterestRate = annualInterestRate / 12
    const payment = principal * (
      monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPeriods)
    ) / (Math.pow(1 + monthlyInterestRate, totalPeriods) - 1)
    return payment
  }

  static calcSystemCost(solarCost: number, adders: Adder[] = []): number {
    let total = solarCost
    adders.forEach(adder => {
      const amount = adder.amount
      if (adder.description !== AdderTypes.Downpayment) {
        total += amount
      }
    })
    return total
  }

  static calcLoanAmount(solarCost: number, adders: Adder[] = []): number {
    let total = solarCost
    adders.forEach(adder => {
      const amount = adder.amount
      if (adder.description === AdderTypes.Downpayment) {
        total -= amount
      } else {
        total += amount
      }
    })
    return total
  }

  static getPaymentAndAmortizationSchedule(
    term: number,
    loanAmount: number,
    fees: number,
    annualInterestRate: number,
    interestOnlyPayments = 6,
    loanStartDate?: Date,
    firstPeriodDate?: Date,
  ): AmortizationSchedule {
    // Daily interest rate for 365/365 method
    const loanDate = loanStartDate ? new Date(loanStartDate) : new Date()
    const rate = annualInterestRate > 1 ? annualInterestRate / 100 : annualInterestRate
    const dailyInterestRate = rate / 365
    const firstStatementDate = firstPeriodDate || this.firstStatementDate(loanDate)
    const amountFinanced = loanAmount + fees
    const daysInFirstPeriod = daysBetween(loanDate, firstStatementDate)
    const finalPaymentDate = this.finalPaymentDate(loanDate, term)

    const requiredFields = (
      !term || 
      !loanAmount || 
      fees === undefined || 
      annualInterestRate === undefined
    )
    
    if (requiredFields) {
      throw Error(`cannot calculate payment; required parameters: term=${term}, annualInterestRate=${annualInterestRate}, loanAmount=${loanAmount}, fees=${fees}`)
    }
    
    if (daysInFirstPeriod < 5) {
      console.warn(`daysInFirstPeriod is less than 5: ${daysInFirstPeriod}; may create problems in amortization calculation`)
    }

    if (annualInterestRate > 1) {
      console.warn(`annualInterestRate of ${annualInterestRate} is greater than 1; assuming you meant ${rate}`)
    }

    // eslint-disable-next-line max-len
    // console.log(`calculating payment (date=${mmddyyyy(loanDate)}, firstStatementDate=${mmddyyyy(firstStatementDate)}, amountFinanced=${amountFinanced}, annualInterestRate=${rate}, term=${term}, interestOnlyPayments=${interestOnlyPayments})`)

    // initial guess at the fixed payment amount
    let fixedPaymentAmount = this.estimatedFixedPayment(amountFinanced, rate, term)

    // Loop to generate the payment schedule
    let attempts = 0
    let schedule: PaymentScheduleItem[] = []
    let adjustmentRatio = 0.4
    let offByPct
    // outstanding pricipal is misleading; more like outstanding balance 
    // w/o accrued non-compounded interest
    let outstandingPrincipal = amountFinanced
    let offBy = 1_000_000

    // assumptions about when funds are disbursed; by default, 
    // assume full balance is disbursed on loan date
    let disbursementSchedule = [amountFinanced]
    if (interestOnlyPayments > 2) {
      // if interest only period, assume fees at first, then half the balance for periods 1 and 2
      disbursementSchedule = [
        fees, 
        loanAmount / 2,
        loanAmount / 2
      ]
    }

    // tolerance for gap between payment amount and remaining principal in dollars
    const tolerance = 0.0001

    do {
      schedule = []
      attempts++
      outstandingPrincipal = 0

      let nonCompoundedInterest = 0
      let currentDate = new Date(loanDate)
      let nextDate = firstStatementDate
      let prior

      for (let i = 0; i <= term; i++) {
        // final date needs to be exactly term months from loan date
        nextDate = i === term - 1 ? finalPaymentDate : nextDate
        // daysInPeriod is not relevant for final payment since it's based on previous period
        let daysInPeriod = i === term ? 0 : daysBetween(currentDate, nextDate)

        // Calculate the days in this period
        let paymentAmount = fixedPaymentAmount
        // days in prior period is key to calculating interest
        let interest = outstandingPrincipal * dailyInterestRate * (prior?.daysInPeriod || 0)
        // during intro period, non-compounding interest is accrued and paid off first
        if (i <= interestOnlyPayments) {
          paymentAmount = 0
          nonCompoundedInterest += interest
        } else {
          outstandingPrincipal += interest
          if (nonCompoundedInterest > 0) {
            // apply payment to non-compounded interest first
            nonCompoundedInterest -= paymentAmount
            if (nonCompoundedInterest < 0) {
              // if there's any left over, apply it to the outstanding principal
              outstandingPrincipal += nonCompoundedInterest
            }
          } else {
            outstandingPrincipal -= paymentAmount
          }
        }

        outstandingPrincipal += disbursementSchedule[i] || 0

        // Add the payment details to the schedule
        prior = {
          date: currentDate,
          daysInPeriod,
          interest,
          nonCompoundedInterest: Math.max(0, nonCompoundedInterest),
          paymentAmount,
          outstandingPrincipal
        }

        schedule.push(prior)

        // Move to the next period
        currentDate = nextDate
        nextDate = this.nextMonth(nextDate)
      }

      offBy = Math.abs(outstandingPrincipal)

      if (offBy > tolerance) {
        let pct = outstandingPrincipal / fixedPaymentAmount
        if (Math.abs(pct) > Math.abs(offByPct)) {
          // we're getting further away from the target, so decrease the adjustment ratio
          // eslint-disable-next-line max-len
          // console.log(`off more than previous attempt; decreasing adjustment ratio from ${adjustmentRatio} to ${adjustmentRatio / 2}`)
          adjustmentRatio = adjustmentRatio / 2
        } else if (Math.abs(pct / offByPct) > 0.8 && pct / offByPct < 0) {
          // not converging fast enough; adjustments are too big and we're oscillating over/under
          // eslint-disable-next-line max-len
          // console.log(`oscillating; decreasing adjustment ratio from ${adjustmentRatio} to ${adjustmentRatio / 2}`)
          adjustmentRatio = adjustmentRatio / 2
        } else if (Math.abs(pct / offByPct) > 0.8 && pct / offByPct > 0) {
          // we're converging too slowly but not oscillating; make bigger adjustments
          // eslint-disable-next-line max-len
          // console.log(`not converging fast enough; increasing adjustment ratio from ${adjustmentRatio} to ${adjustmentRatio * 1.2}`)
          adjustmentRatio = adjustmentRatio * 1.2
        }

        offByPct = pct
        fixedPaymentAmount = fixedPaymentAmount + (pct * adjustmentRatio)
      }
    } while (attempts < 200 && offBy > tolerance)

    if (offBy > tolerance) {
      throw Error(`Failed to calculate payment for loan amount ${amountFinanced} with interest rate:${annualInterestRate} term:${term} iter:${attempts} iop:${interestOnlyPayments}`)
    }

    // eslint-disable-next-line max-len
    // console.log(`calculated payment amount ${fixedPaymentAmount} in ${attempts} iterations with delta ${offBy}`)
    // console.table(schedule)

    const sumOfTotalPayments = this.sumOfTotalPayments(schedule)
    const financeCharge = sumOfTotalPayments - loanAmount
    const firstPaymentDate = new Date(firstStatementDate)
    firstPaymentDate.setMonth(firstPaymentDate.getMonth() + interestOnlyPayments)

    return {
      schedule,
      sumOfTotalPayments,
      payment: fixedPaymentAmount,
      firstPeriodDate,
      firstPaymentDate,
      finalPaymentDate,
      financeCharge,
      loanDate,
      loanAmount,
    }
  }

  private static nextMonth(d: Date): Date {
    const next = new Date(d)
    next.setMonth(next.getMonth() + 1)
    // Handle overflow when moving from the 31st to shorter months
    if (next.getDate() !== d.getDate()) {
      next.setDate(0) // Set to the last day of the month if overflow
    }

    return next
  }

  static sumOfTotalPayments(schedule: PaymentScheduleItem[]): number {
    // Number(n.toFixed(2)) is effectively rounding
    return schedule.reduce((acc, item) => acc + Number(item.paymentAmount.toFixed(2)), 0)
  }

  static sumOfInterest(schedule: PaymentScheduleItem[]): number {
    return schedule.reduce((acc, item) => acc + item.interest, 0)
  }

  static apr(
    amountFinanced: number,
    numPayments: number,
    knownMonthlyPayment: number
  ): number {
    // Iterative binary search to find the APR
    let low = 0 // Minimum APR (0%)
    let high = 100 // Maximum APR (100%)
    let attempts = 0
    const precision = 1e-5 // Precision threshold

    while (high - low > precision) {
      attempts++
      const mid = (high + low) / 2
      const testRate = (mid / 100) / 12 // Convert APR to monthly rate
      const testPayment = ((amountFinanced) * testRate) /
        (1 - Math.pow(1 + testRate, -numPayments))

      if (testPayment > knownMonthlyPayment) {
        high = mid // Too high, lower the APR
      } else {
        low = mid // Too low, raise the APR
      }
    }

    // console.log(`apr=${(high + low) / 2} after ${attempts} attempts`)
    return (high + low) / 2 // Approximate APR
  }

  static calculateLoanTermInYears(months: number): string {
    return (months / 12).toFixed(1)
  }
}
