import React, { useRef } from "react"
import { v4 } from "uuid"
import "./checkbox.scss"

type CheckboxProps = {
  label: string
  value: boolean
  disabled?: boolean
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox = ({ label, value, disabled, onChange }: CheckboxProps) => {
  const id = useRef(`cb-${v4()}`)

  return <div className="oe-cb-group">
    <input type="checkbox"
      className="p-2"
      id={id.current}
      onChange={onChange}
      checked={value || false}
      disabled={disabled}
    />
    <label className={`p-2 ${disabled ? 'text-muted' : ''}`} htmlFor={id.current}>{label}</label>
  </div>
}

export default Checkbox