import { SubformProps } from "."

export const Submitted = ({ formData }: Partial<SubformProps>) => {
  if (![formData.prequalResult, formData.prequalResultCombined].includes('pass')) {
    return <div className="alert alert-info">
      Your application has been received and is pending review. A member of our
      banking team will reach out to you within 2 business days to discuss next steps.
    </div>
  }

  return <div className="alert alert-success">
    <div className="mb-3"><b>Congratulations!</b></div>
    {formData.envelopes?.length ? <div className="mb-3">
      Your application has been submitted and is approved pending document verification.
      You should receive a documents package via Docusign in your email shortly. These
      documents must be signed to complete the application process. After signing, a Loan
      Officer will contact you within 2 business days to review the application and
      finalize your loan.
    </div> : <div>
      Your application has been pre-qualified pending document verification. A member of
      our banking team will reach out within 2 business days to continue the application
      process.
    </div>}
  </div>
}

