import { ITenant, Incentive } from "@oneethos/shared"
import { IEquipmentCard } from "./equipments"

type LoanDetailLogosProps = {
  financingTenant: ITenant
  incentive?: Incentive
  isOrgTeslaCertified: boolean
  selectedEquipment?: IEquipmentCard | null
  isResidential: boolean 
}

const LoanDetailLogos = ({
  financingTenant,
  incentive,
  isOrgTeslaCertified,
  selectedEquipment,
  isResidential
}: LoanDetailLogosProps) => {

  return (
    <div className="mt-6 d-flex">
      <div className="form-group">
        <label>Financing provided by:</label>
          {/* TODO: update cache-busting */}
        <img src={`/api/tenant/logo/${financingTenant._id}`} loading="lazy" id="logo"
          sizes="(max-width: 479px) 83vw, (max-width: 767px) 72vw, 397.8984375px"
          alt="financing provider logo"
          style={{ maxHeight: '50px' }}
        />
      </div>
      {incentive ? 
      <div className="form-group">
        <label>In partnership with:</label>
        <img src={incentive.financingPartnerLogo} loading="lazy" id="logo"
          sizes="(max-width: 479px) 83vw, (max-width: 767px) 72vw, 397.8984375px"
          alt="local financing partner logo"
          style={{ maxHeight: '50px' }}
        />
      </div> : null}
      {isOrgTeslaCertified && selectedEquipment?.isTesla && isResidential ? 
      <div className="form-group">
        <label>In partnership with:</label>
        <img src="images/tesla-logo.png" loading="lazy" id="logo"
          sizes="(max-width: 479px) 83vw, (max-width: 767px) 72vw, 397.8984375px"
          alt="local financing partner logo"
          style={{ maxHeight: '50px' }}
        />
      </div> : null}
    </div>)
}

export default LoanDetailLogos