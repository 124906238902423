import React, { useMemo } from 'react'
import { SubformProps } from "."
import ReviewApplication from './review-application'
import { useAppState } from '../../hooks'
import LoanSummary from '../../components/loan-summary'
import { dollarsToNum, LoanCalculations } from '@oneethos/shared'

export const SubmitApplication = (props: SubformProps) => {
  const { formData, onSubmit } = props
  const { tenant } = useAppState()

  const loanTermInYears = LoanCalculations.calculateLoanTermInYears(formData.loanDurationMonths)

  let rate = `${(formData.useAutoDebit === 'no' ? formData.interest + 0.25 : formData.interest)}%`
  if (formData.incentive) {
    rate = `${formData.incentive.nonIncentiveRate}% before incentives**`
  }

  const amortization = useMemo(() => {
    const closingCosts = LoanCalculations.estimatedClosingCosts(
      dollarsToNum(formData.loanAmount),
      formData.installationAddress?.state,
      formData.projectType
    )

    // TODO: product ids are different depending on support or non-support user
    const product = tenant.config.products.find(p => [p._id, p.id].includes(formData.productId))

    return LoanCalculations.getPaymentAndAmortizationSchedule(
      formData.loanDurationMonths,
      dollarsToNum(formData.loanAmount),
      closingCosts,
      formData.useAutoDebit === 'no' ? product.interestRateWithoutAutopay : product.interestRateWithAutopay,
      product.interestOnlyPeriod
    )
  }, [formData.useAutoDebit])

  return <div className="submit-application">
    <p>
      Almost done! Before submitting your application, please review all details for accuracy.
    </p>
    <ReviewApplication {...props} />
    <h2 className="mt-5">Submit Application Request</h2>
    <div className="sa-summary my-4">
      <p>
        You're applying for a {loanTermInYears}-year loan ({formData.loanDurationMonths} months)
        at a {rate} interest rate. Here's a quick summary of your loan details:
      </p>
      <LoanSummary
        formData={formData}
        incentive={formData.incentive}
        showApr={formData.projectType === 'residential' && !formData.incentive}
        amortization={amortization}
      />
    </div>
    {formData.incentive ? <div className="text-small mb-3 fst-italic">
      **With qualifying incentive. Incentive terms can be found <a target="_blank" href={formData.incentive.termsUrl}>here</a>.
    </div> : null}
    <p>
      By submitting your application request you certify that complete and accurate responses have
      been provided for all questions on the application, and agree to allow {tenant.config.name} to
      verify your personal information and check your credit.
    </p>
    <div className="buttons text-start">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => onSubmit()}
      >Submit Application</button>
      {process.env.REACT_APP_NODE_ENV === 'test' ? <>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => onSubmit({ test: true })}
        >Test Submit</button>
      </> : null}
    </div>
    {process.env.REACT_APP_NODE_ENV === 'test' ? <div className="alert alert-secondary mt-3">
      Clicking "Test Submit" will pull DIT, send docs, and simulate the user experience,
      but will NOT actually submit to the lender; e.g., account numbers will be faked.
    </div> : null}
  </div>
}

export default SubmitApplication