import { useContext, useEffect, useState } from 'react'
import { Section } from '../../components'
import {
  ValidatedInput,
  DateInput,
  EmailInput,
  PhoneInput,
  SocialInput,
  DollarsInput,
  DollarsNonZeroInput,
  RoutingNumberInput,
  IntegerInput,
  DateOfBirthInput,
  LicenseIssueDateInput,
  LicenseExpirationDateInput
} from '../../components/forms'
import { FormSchema, FormInputProps, pause, Org, PrequalResult } from '@oneethos/shared'
import './consumer-application-form.scss'
import {
  dollars,
  errorToString,
  LoanApplication,
  PreSubmitStatus,
  PreSubmitStatusOrder
} from '@oneethos/shared'
import { useAppState, useUtmQuery } from '../../hooks'
import api from '../../api-client'
import { Spinner, SpinnerSize } from '@fluentui/react'
import ApplicationMenu from './menu'
import { Eligibility } from './eligibility'
import InitialLoanRequest from './initial-loan-request'
import ContactInformation from './contact-information'
import Employment from './employment'
import Coborrower from './coborrower'
import IncomeVerification from './income-verification'
import AutoDebitAuthorization from './auto-debit-authorization'
import { SubmitApplication } from './submit-application'
import { Submitted } from './submitted'
import { Navigate, useParams } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import actions from '../../actions'
import { AppContext } from '../../appContext'
import { formDataToLoanApp, lappToFormData } from './util'
import { UnableToProceed } from './unable-to-proceed'
import { ConsumerErrorView } from './consumer-error-view'

// This approach is slightly problematic because there are several other fields that require custom 
// treatment, so this is only a partial list
const schema: FormSchema = {
  loanAmount: {
    label: 'Loan Amount',
    type: 'dollars'
  },
  solarCost: {
    label: 'Solar Price',
    type: 'dollars'
  },
  adders: {
    type: 'dollars',
  },
  avgMonthlyUtilityBill: {
    label: 'Average Monthly Utility Bill',
    type: 'dollarsNonZero'
  },
  customerName: {
    label: 'Full Name',
  },
  firstName: {
    label: 'First Name',
  },
  middleName: {
    label: 'Middle',
  },
  lastName: {
    label: 'Last Name',
  },
  ssn: {
    label: 'Social Security Number',
    type: 'ssn'
  },
  phone: {
    label: 'Mobile Phone (must validate via SMS)',
    type: 'phone'
  },
  email: {
    label: 'Email',
    type: 'email'
  },
  spouseEmail: {
    label: 'Spouse\'s Email',
    type: 'email'
  },
  spouseFirstName: {
    label: 'Spouse\'s First Name',
  },
  spouseLastName: {
    label: 'Spouse\'s Last Name',
  },
  birthDate: {
    label: 'Birth Date',
    type: 'dob'
  },
  driversLicenseIssueDate: {
    label: 'Drivers License Issue Date',
    type: 'licenseIssue'
  },
  driversLicenseExpirationDate: {
    label: 'Drivers License Expiration Date',
    type: 'licenseExpiration'
  },
  driversLicenseNo: 'Drivers License Number',
  driversLicenseIssuingState: 'Drivers License Issuing State',
  statedGrossAnnualIncome: {
    type: 'dollars',
    label: 'Annual Income (before taxes)'
  },
  statedHouseholdIncome: {
    type: 'dollars',
    label: 'Annual Household Income (before taxes)'
  },
  citizenship: 'Citizenship',
  userAddress: {
    label: 'Home Address',
    type: 'address'
  },

  // employment
  employerName: 'Employer Name',
  occupation: 'Occupation/Title',
  yearsAtEmployer: 'Time employed at current job',

  // autodebit
  autoDebitInstitution: 'Depository Institution',
  autoDebitRoutingNo: {
    label: 'Routing Number',
    type: 'routing'
  },
  autoDebitAccountNo: 'Account Number',

  // coborrower
  coFirstName: 'Co-Borrower First Name',
  coMiddleName: 'Middle',
  coLastName: 'Co-Borrower Last Name',
  coBorrowerAddress: {
    label: 'Co-Borrower Home Address',
    type: 'address'
  },
  coCitizenship: 'Co-Borrower Citizenship',
  coSsn: {
    label: 'Co-Borrower Social Security Number',
    type: 'ssn'
  },
  coPhone: {
    label: 'Co-Borrower Mobile Phone',
    type: 'phone'
  },
  coEmail: {
    label: 'Co-Borrower Email',
    type: 'email'
  },
  coBirthDate: {
    label: 'Co-Borrower Birth Date',
    type: 'dob'
  },
  coDriversLicenseNo: 'Co-Borrower Drivers License Number',
  coDriversLicenseIssuingState: 'Co-Borrower Drivers License Issuing State',
  coDriversLicenseIssueDate: {
    label: 'Co-Borrower Drivers License Issue Date',
    type: 'licenseIssue'
  },
  coDriversLicenseExpirationDate: {
    label: 'Co-Borrower Drivers License Expiration Date',
    type: 'licenseExpiration'
  },
  coStatedGrossAnnualIncome: {
    label: 'Co-Borrower Annual Income (before taxes)',
    type: 'dollarsNonZero'
  },
  coEmployerName: 'Co-Borrower Employer',
  coOccupation: 'Co-Borrower Occupation',
  coYearsAtEmployer: 'Co-Borrower Time Employed at Current Job',
}

// TODO: This should really be called SchemaInput and only used in a schema context
export const FormInput = ({ field, label, ...props }: FormInputProps) => {
  const config = schema[field]
  let component
  switch (config.type) {
    case 'ssn': component = <SocialInput
      {...props}
      onChange={s => {
        const val = s.trim().replace(/[^0-9]/g, '')
        if (val.length < 4) return props.onChange(val)
        if (val.length < 6) {
          return props.onChange(`${val.slice(0, 3)}-${val.slice(3)}`)
        }

        props.onChange(`${val.slice(0, 3)}-${val.slice(3, 5)}-${val.slice(5, 9)}`)
      }}
    />; break
    case 'phone': component = <PhoneInput {...props} />; break
    case 'email': component = <EmailInput {...props} />; break
    case 'integer': component = <IntegerInput {...props} />; break
    case 'routing': component = <RoutingNumberInput {...props} />; break
    case 'dollars': component = <DollarsInput {...props} />; break
    case 'dollarsNonZero': component = <DollarsNonZeroInput {...props} />; break
    case 'dob': component = <DateOfBirthInput {...props} />; break
    case 'licenseIssue': component = <LicenseIssueDateInput {...props} />; break
    case 'licenseExpiration': component = <LicenseExpirationDateInput {...props} />; break
    case 'date': component = <DateInput {...props} />; break
    default: component = <ValidatedInput
      {...props}
      validator={s => s ? '' : 'required'}
    />
  }

  return <div className="form-group">
    <label>{label || (typeof config === 'string' ? config : config.label) || field}</label>
    {component}
  </div>
}

const steps: Record<PreSubmitStatus, any> = {
  'Loan Request': InitialLoanRequest,
  'Eligibility': Eligibility,
  'Contact Information': ContactInformation,
  'Employment': Employment,
  'Co-Borrower': Coborrower,
  'Income Verification': IncomeVerification,
  'Auto-Debit Authorization': AutoDebitAuthorization,
  'Submit Application': SubmitApplication,
  'Submitting': SubmitApplication,
}

type SaveProps = {
  data?: Partial<LoanApplication> & {
    testPrequalResult?: PrequalResult
    testPrequalResultCombined?: PrequalResult
  },
  advance?: boolean,
  onCatch?: (e: string | Error) => void
}

export type SubformProps = {
  formData: Partial<LoanApplication> & { 
    loanAmount: string, 
    avgMonthlyUtilityBill: string,
    solarCost: string,
    adders: { description: string, amount: string }[]
  }
  org: Org
  update: (data: string | Partial<LoanApplication>, value?: unknown) => void
  save: (s?: SaveProps) => Promise<void>
  onError: (err: string) => void
  onSubmit: (opts?: { test: boolean }) => void
  saving: boolean
  schema: FormSchema
}

export const Validation = ({ validation }) => {
  const entries = Object.entries(validation)
  return entries.length ? <div className="caf-error alert alert-danger">
    {entries.map(([field, error], i) => <div key={i}>
      {error}
    </div>)}
  </div> : null
}

export const ConsumerApplicationForm = ({ id, token }) => {
  const { dispatch, state: { registration: { authError }, tenant } } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [step, setStep] = useState<PreSubmitStatus | ''>('')
  const [org, setOrg] = useState()
  const [formData, setFormData] = useState<Partial<LoanApplication>>({
    preSubmitStatus: PreSubmitStatusOrder[0]
  })

  // only add the token if it's passed to prevent compexity in router auth
  const tauth = token ? `?t=${token}` : ''

  const fetchLoanApp = async () => {
    return api.get(`/c/loanapps/${id}${tauth}`).then(({ lapp, org }) => {
      setOrg({ operatingState: [], ...org })

      setFormData(lappToFormData(lapp))

      if (lapp.incentive) {
        dispatch(actions.setCobranding(lapp.incentive))
      }

      return lapp
    })
  }

  const pollUntilDone = () => {
    const poll = async () => {
      try {
        const { lapp } = await api.get(`/c/loanapps/${id}${tauth}`)
        if (['Submitting'].includes(lapp.preSubmitStatus)) {
          await pause(3)
          poll()
        } else {
          setFormData(lappToFormData(lapp))
        }
      } catch (ex) {
        handleError(ex)
      }
    }

    poll()
  }

  useEffect(() => {
    fetchLoanApp().then(lapp => {
      if (['Submitting', 'Generating Loan Documents'].includes(lapp.preSubmitStatus)) {
        pollUntilDone()
      }
    }).catch(ex => {
      dispatch(actions.setAuthError(ex.code || errorToString(ex)))
    }).finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    setStep('')
  }, [formData.preSubmitStatus])

  const update = (data: string | Partial<LoanApplication>, value?: any) => {
    if (typeof data === 'string') {
      setFormData({
        ...formData,
        [data]: value
      })
    } else if (typeof data === 'object') {
      setFormData({ ...data })
    }
  }

  const save = async (data): Promise<LoanApplication> => {
    const lapp = formDataToLoanApp(data)
    return api.patch(`/c/loanapps/${formData._id}${tauth}`, lapp)
  }

  const handleError = (ex) => {
    if (Array.isArray(ex.error)) {
      toast.error(
        <div>
          <div>Please fix the following problems:</div>
          {ex.error.map((err, i) => <div key={i}>{err}</div>)}
        </div>
        , { autoClose: false })
    } else {
      const msg = errorToString(ex)

      toast.error(msg, { autoClose: false })
    }
  }

  if (loading) {
    return <Section>
      <Spinner />
    </Section>
  }

  if (authError) {
    const query = new URLSearchParams({ route: `/apply/${id}` })
    const url = `/login?${query.toString()}`
    return <Navigate to={url} />
  }

  if (formData.preSubmitStatus === 'Submitting') {
    return <div className="submitting">
      <h3>Your application is being submitted</h3>
      <p>This can take a few moments...</p>
      <Spinner size={SpinnerSize.large} style={{ margin: '2em' }} />
    </div>
  } else if (formData.preSubmitStatus === 'Submitted') {
    return <Submitted formData={formData} update={update} />
  }

  if (formData.error) {
    return <ConsumerErrorView />
  }

  if (formData.isLocked) {
    return <UnableToProceed />
  }

  const Content = steps[step || formData.preSubmitStatus]

  return <div className='consumer-application-form'>
    <ApplicationMenu
      maxStep={formData.preSubmitStatus}
      onNav={setStep}
      statuses={PreSubmitStatusOrder.filter(s => s !== 'Submitted')}
      currentStep={step || formData.preSubmitStatus}
    />
    <div className="caf-content">
      {formData.error || formData.submissionResponse?.error ? <div>
        <div className="alert alert-danger">
          {formData.error || formData.submissionResponse.error}
        </div>
      </div> : null}
      <Content
        formData={formData}
        org={org}
        update={update}
        saving={saving}
        onError={handleError}
        schema={schema}
        save={async (opts) => {
          const {
            data,
            advance = true, // continue to the next step by default
            onCatch         // override catch handler; otherwise will default to setting error
          } = opts || {}

          const _then = lapp => {
            const nextData = { ...lapp }
            if (advance) {
              const stepStatus = step || nextData.preSubmitStatus
              const idx = PreSubmitStatusOrder.findIndex(s => s === stepStatus)
              nextData.preSubmitStatus = PreSubmitStatusOrder[idx + 1]
            }
            setStep('')
            setFormData({
              ...nextData,
              loanAmount: dollars(nextData.loanAmount?.toString() || '0'),
              solarCost: dollars(nextData.solarCost?.toString() || '0'),
              adders: nextData.adders?.map(adder => ({  
                ...adder,
                amount: dollars(adder.amount?.toString() || '0')
              })),
              avgMonthlyUtilityBill: (
                nextData.avgMonthlyUtilityBill ?
                  dollars(nextData.avgMonthlyUtilityBill?.toString()) :
                  undefined
              )
            })
          }

          setSaving(true)
          if (onCatch) {
            return save(data || formData)
              .then(_then)
              .catch(onCatch)
              .finally(() => setSaving(false))
          } else {
            return save(data || formData)
              .then(_then)
              .catch(handleError)
              .finally(() => setSaving(false))
          }
        }}
        onSubmit={async (opts) => {
          try {
            setFormData({ ...formData, preSubmitStatus: 'Submitting' })
            await save(formData)
            const lapp = await api.post(`/c/loanapps/${id}/submit`, { test: opts?.test })
            setFormData({ ...lapp })
            pollUntilDone()
          } catch (ex) {
            setFormData({ ...formData, preSubmitStatus: 'Submit Application' })
            handleError(ex)
          }
        }}
      />
    </div>
  </div>
}

export const ConsumerApplicationPage = () => {
  const query = useUtmQuery()
  const { id } = useParams()
  const { tenant } = useAppState()

  let payload
  if (query.t) {
    try {
      payload = jwtDecode(query.t)
    } catch (ex) {
      return <Section>
        <div className="alert alert-danger">
          Error initializing page: {ex.message}
        </div>
      </Section>
    }
  }

  if (payload?.id && id && payload.id !== id) {
    return <Section>
      <div className="alert alert-danger">
        Could not load application. Please try using the original
        link you received to begin the application.
      </div>
    </Section>
  }

  if (!id && !payload?.id) {
    return <Section>
      To provide you with the best service and ease of install, we
      require all applicants to work with a {tenant.config.name} Approved
      Preferred Solar Installer. Please contact us at
      {tenant.config.bankEmail} to request information on a
      {tenant.config.name} Approved Preferred Solar Installer in your area.
    </Section>
  }

  return <Section>
    <ConsumerApplicationForm
      id={id || payload?.id}
      token={query.t}
    />
  </Section>
}

export default ConsumerApplicationPage
