import { EquipmentType } from '@oneethos/shared'
import React, { useEffect, useState } from 'react'

export interface IEquipmentCard {
  id: string
  image: string
  title: string
  description: string
  isTesla?: boolean
}

const equipmentCards: IEquipmentCard[] = [
  {
    id: EquipmentType.TeslaRoof,
    image: '/images/tesla-roof.png',
    title: 'Tesla Roof + Powerwall 3',
    description: 'New luxury integrated solar roof with home battery backup',
    isTesla: true
  },
  {
    id: EquipmentType.TeslaSolar,
    image: '/images/tesla-solar.png',
    title: 'Tesla Solar Panels + Powerwall 3',
    description: 'Panels for your roof with home battery backup',
    isTesla: true
  },
  {
    id: EquipmentType.StandardSolar,
    image: '/images/solar-panel.jpeg',
    title: 'Standard Solar',
    description: 'Standard solar, storage and/or roof',
    isTesla: false
  }
]

interface EquipmentsProps {
  onChange?: (selectedEquipment: IEquipmentCard | null) => void
  showAll?: boolean
}

const Equipments = ({ onChange, showAll = false }: EquipmentsProps) => {

  const [selectedCard, setSelectedCard] = useState<string>(EquipmentType.TeslaRoof)

  useEffect(() => {
    const defaultCard = equipmentCards.find(card => card.id === EquipmentType.TeslaRoof)
    if (defaultCard) {
      onChange?.(defaultCard)
    }
  }, [onChange])

  const handleCardSelect = (card: IEquipmentCard) => {
    setSelectedCard(card.id)
    onChange?.(card)
  }

  const displayedCards = showAll 
    ? equipmentCards 
    : equipmentCards.slice(0, 1)

  return (
    <div className='d-flex flex-column flex-md-row justify-content-between gap-3 mb-3'>
      {displayedCards.map((card) => (
        <div
          key={card.id}
          className={`card pointer equipment-card ${selectedCard === card.id ? 'border-primary border-2' : ''}`}
          onClick={() => handleCardSelect(card)}
        >
          <img
            src={card.image}
            className="card-img-top"
            alt={card.title}
          />
          <div className="card-body">
            <h5 className="card-title">{card.title}</h5>
            <p className="card-text">{card.description}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Equipments
