import { CountryOptions, StateOptions } from './state-select'
import { IStreetAddress } from '@oneethos/shared'
import SearchWithSuggestions from './search-with-suggestions'
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { ZipInput } from './inputs'
import './address-inputs.scss'
import { useEffect, useState } from 'react'

export type AddressProps = {
  label?: string
  address: IStreetAddress
  onChange: (address: IStreetAddress) => void
}

export const AddressInputs = ({ label, address = {}, onChange }: AddressProps) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    options: {
      types: ['address'],
      componentRestrictions: { country: ['us'] }
    },
    debounce: 300,
    language: 'en_US'
  })

  const [line1, setLine1] = useState(address.streetAddress || '')

  useEffect(() => {
    setLine1(address.streetAddress || '')
  }, [address.streetAddress])

  return <div className="address-inputs">
    <div className="form-group">
      <label>{label || 'Address'}</label>
      <SearchWithSuggestions
        autoFocus={false}
        placeholder="Address Line 1 (required)"
        onSelect={(place) => placesService?.getDetails({
          placeId: place.place_id,
        }, (placeDetails) => {
          const city = placeDetails.address_components.find(c => c.types.includes('locality'))
          const state = placeDetails.address_components.find(c => c.types.includes('administrative_area_level_1'))
          const zip = placeDetails.address_components.find(c => c.types.includes('postal_code'))
          const country = placeDetails.address_components.find(c => c.types.includes('country'))

          onChange({
            ...address,
            city: city?.short_name || '',
            state: state?.short_name || '',
            zip: zip?.short_name || '',
            streetAddress: placeDetails.name,
            country: country?.long_name
          })
        })}
        query={line1 || ''}
        onQueryChange={q => {
          setLine1(q)
          getPlacePredictions({ input: q })
        }}
        onBlur={() => {
          onChange({
            ...address, 
            streetAddress: line1 || ''
          })
        }}
        suggestions={placePredictions.map(pp => ({ label: pp.description, ...pp }))}
      />
    </div>

    <div className="form-group">
      <input
        type="text"
        className="form-control"
        placeholder="Address Line 2 (optional)"
        value={address?.streetAddressLine2 || ''}
        onChange={ev => onChange({
          ...address,
          streetAddressLine2: ev.target.value
        })}
      />
    </div>
    <div className="form-group addr-region">
      <div className="addr-city">
        <label>City</label>
        <input
          type="text"
          className="form-control"
          placeholder="City"
          value={address?.city || ''}
          onChange={ev => onChange({
            ...address,
            city: ev.target.value
          })}
        />
      </div>
      <div className="addr-state">
        <label>State</label>
        <select
          className="form-select"
          value={address?.state || ''}
          onChange={ev => onChange({
            ...address,
            state: ev.target.value
          })}
        >
          <option>Choose state...</option>
          <StateOptions />
        </select>
      </div>
      <div className="addr-zip">
        <label>Zip</label>
        <ZipInput
          placeholder="ZIP/Postal Code"
          onChange={s => onChange({
            ...address,
            zip: s.replace(/[^0-9]/gi, '').substring(0, 5)
          })}
          value={address?.zip || ''}
        />
      </div>
      <div className="addr-country">
        <label>Country</label>
        <select
          className="form-select col-12"
          placeholder="Country"
          value={address?.country}
          onChange={ev => onChange({
            ...address,
            country: ev.target.value
          })}
        >
          <option>Select Country</option>
          <CountryOptions />
        </select>
      </div>
    </div>
  </div >
}
