import { useContext } from "react"
import { AppContext } from "../../appContext"


export const ConsumerErrorView = () => {
  const { state: { tenant } } = useContext(AppContext)

  return <div>
    Thank you for your interest in working with {tenant.config.name}.
    We have received your information and a bank representative will be in
    touch with you shortly to proceed with your application.

    If you have any questions or concerns in the meantime, we'd love to hear
    from you at {tenant.config.bankEmail}.
  </div>
}