import React, { useState } from 'react'
import { FaChevronDown, FaChevronRight } from "react-icons/fa"
import { EquifaxScoreModel } from '@oneethos/shared'

type ScoreProps = { model: EquifaxScoreModel }

const Score = ({ model }: ScoreProps) => {
  const [expand, setExpand] = useState(true)
  const scoreColor = model.score && model.score >= 680 ? 'text-success' : 'text-danger'

  return (
    <div className="cr-scoring" >
      {model.rejects?.map(({ code }) => <div key={code} className="cr-reject score">Score: Reject ({code})</div>)}
      {model.score ? <>
        <div className="score" onClick={() => setExpand(!expand)}>
          <span className={scoreColor}>Score: {model.score}</span>
          {expand ? <FaChevronDown size='15' /> : <FaChevronRight size='15' />}
        </div>
        {expand ? 
          <div className='border-bottom mb-3'>
            {model.reasons?.map(r => 
              <div key={r.code} className='my-2 ms-3'>
                <span className='text-secondary fw-bold'>{r.code}</span>
                <span className='text-secondary'> | {r.description}</span>
              </div>)
            }
          </div> : null
        }</> : null
      }
    </div >
  )
}

export default Score