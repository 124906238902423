import { useState } from "react"
import { EquifaxBankruptcy, yyyymmdd } from "@oneethos/shared"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"

type BankruptcyProps = {
  bankruptcies: EquifaxBankruptcy[]
}

export const CodedDescriptor = ({ code, description }) => {
  if (!code) return null

  return (
    <div
    title={description}
    style={{
      whiteSpace: 'nowrap',
      maxWidth: '150px',
      fontSize: '0.8em',
      overflow: 'hidden',
      marginRight: '0.3em',
    }}><b>{code}</b> | <span style={{ color: '#aaa' }}>{description}</span>
    </div>
  )
}

const Bankruptcies = ({ bankruptcies }: BankruptcyProps) => {
  const [expand, setExpand] = useState(true)

  if (bankruptcies.length === 0) {
    return (
      <div className="fs-5 mb-2">
        <div>Bankruptcies (0)</div>
      </div>
    )
  }

  return (
    <div className="mb-2">
      <div className="section-hdr" onClick={() => setExpand(!expand)}>
        Bankruptcies ({bankruptcies.length})
        {expand ? <FaChevronDown size='15' /> : <FaChevronRight size='15' />}
      </div>

      {expand ?
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Filed</th>
                <th>Industry</th>
                <th>Filer</th>
                <th>Type</th>
                <th>Intent/Disposition</th>
                <th>Prior Int/Dis</th>
              </tr>
            </thead>
            <tbody>
              {bankruptcies?.map((b, i) => <tr key={i} title={JSON.stringify(b, null, ' ')}>
                <td>{yyyymmdd(b.dateFiled)}</td>
                <td>{b.industryCode}</td>
                <td>{b.filer}</td>
                <td>{b.type}</td>
                <td><CodedDescriptor {...b.currentIntentOrDispositionCode} /></td>
                <td><CodedDescriptor {...b.priorIntentOrDispositionCode} /></td>
              </tr>)}
            </tbody>
          </table> 
        </div> : null}
    </div >)
}

export default Bankruptcies