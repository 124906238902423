import { useState } from "react"
import { EquifaxPrequalReport, dollars, dateAndTime } from "@oneethos/shared"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import Score from "./score"
import Evaluation from "./evaluation" 
import Bankruptcies from "./bankruptcies"
import Collections from "./collections"
import Trades from "./trades"

export const jsonToCreditItem = json => {
  let report = json
  if (!["prequal", "prequal-combined"].includes(json.reportType) || !json?.report?.consumers) {
    throw new Error("Could not parse credit report")
  }

  report = new EquifaxPrequalReport(json.report.consumers?.equifaxUSConsumerCreditReport[0])
  report.createdDate = new Date(json.createdDate)

  return { report, item: json }
}

type CreditReportItemProps = {
  report: EquifaxPrequalReport
  item: any
}

export const CreditReportItem = ({ report, item }: CreditReportItemProps) => {
  const [showJson, setShowJson] = useState(false)
  const [privacy, setPrivacy] = useState(true)
  const {
    bankruptcies,
    collections,
    inquiries,
    trades,
    models,
    ...rest
  } = report

  const sharedMonthlyDebt = trades
    .filter(t => t.isSharedWithCoapplicant)
    .reduce((acc, t) => {
      return acc + (t.balance > 0 ? t.scheduledPaymentAmount : 0)
    }, 0)

  const evaluation = item.evaluation

  return <div className="list-group-item credit-report" style={{}}>
    <div className="hdr-cont">
      <div className="me-2 pointer" onClick={() => setPrivacy(!privacy)}>{privacy ? <FaEyeSlash /> : <FaEye />}</div>
      <h5 className="m-0" onClick={() => setShowJson(!showJson)}>
        Prequal: {privacy ? '[Redacted]' : item.customerName}
      </h5>

    </div>
    {item.comboName ? <div className="help">Joint Application With: {privacy ? '[redacted]' : item.comboName}</div> : null}
    <div className="help mb-2">Pulled: {dateAndTime(new Date(item.createdDate))}</div>
    {report.isNoHit() ? <>
      <div className="badge text-bg-secondary">No-Hit</div>
    </> : <>
      <div className="cr-summary">
        <div className="info">
          {showJson ? <pre style={{ fontSize: '0.8em' }}>{JSON.stringify({ ...rest, models }, null, ' ')}</pre> : null}
          {report.models?.map((model, i) => <Score key={i} model={model} />)}

          <table className="table mb-3 cr-highlights">
            <tbody>
              <tr>
                <td>Stated Monthly Income</td>
                <td>{dollars(evaluation?.statedGrossMonthlyIncome || evaluation?.statedIncome)}</td>
              </tr>
              <tr>
                <td>Monthly Debt</td>
                <td>{dollars(report.monthlyDebt || 0, 0)}</td>
              </tr>
              {sharedMonthlyDebt ? <tr>
                <td>Shared Monthly Debt</td>
                <td>{dollars(sharedMonthlyDebt || 0, 0)}
                </td>
              </tr> : null}
              <tr>
                <td>Combined Monthly Debt</td>
                <td>{dollars(report.combinedMonthlyDebt || 0, 0)}</td>
              </tr>
              <tr>
                <td>Current DTI</td>
                <td>{item.evaluation?.currentDti?.toFixed(1)}%</td>
              </tr>
              <tr>
                <td>Post DTI</td>
                <td>{item.evaluation?.postDti?.toFixed(1)}%</td>
              </tr>
              <tr>
                <td>Estimated Payment</td>
                <td>{dollars(item.evaluation?.estimatedPayment)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-responsive">
          <table className="late-pay-summary table mb-3 table-bordered">
            <thead>
              <tr className="table-primary">
                <th colSpan={8}>Late Payments</th>
              </tr>
              <tr className="table-secondary">
                <th>&lt;=2</th>
                <th>&lt;=3</th>
                <th>&lt;=4</th>
                <th>&gt;=5</th>
                <th>Collection</th>
                <th>Ch 13</th>
                <th>Repo</th>
                <th>Charge-off</th>
              </tr>
            </thead>
            <tbody>
              {report.latePaymentSummary ? <tr>
                {Object.values(report.latePaymentSummary).map((v, i) => <td key={i}>{v}</td>)}
              </tr> : <tr>
                <td colSpan={8}>Not available</td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>
      <Evaluation evaluation={item.evaluation} />
      <div className="fs-5 text mb-2">Inquiries ({inquiries?.length || 0})</div>
      <Bankruptcies bankruptcies={bankruptcies} />
      <Collections collections={collections} />
      <Trades trades={trades} />
    </>}
  </div>
}

