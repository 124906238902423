import {
  Dictionary,
  LoanDocument,
  LoggedUpload,
  PreSubmitStatus,
  ProjectType,
  RequiredDocConfig,
  FundReleaseRequest,
  Adder,
  StreetAddress
} from './index'

export class StatusData {
  id: string
  lookup?: string
  tenantId?: string
  tenantName?: string
  address: string
  interest: number
  interestOnlyMonths?: number
  loanDurationMonths?: number
  installationAddress?: StreetAddress
  applicationReceived?: string
  nTPGiven?: string
  authorizedInstallers?: string[]
  dueDate?: string  // date of next deadline
  bg?: string
  customerName?: string
  businessName?: string
  description?: string
  deprecated?: boolean
  uploadType?: string
  installersStatus?: string
  owner?: string
  lastModified?: string
  lastStatusChange?: Date
  docsSubmittedText?: string
  uploads?: LoggedUpload[]
  installerDocs?: Dictionary<Dictionary<LoanDocument>>
  isClosed?: boolean
  hasUpload?: LoggedUpload
  createdDate?: Date
  progress?: number
  projectType?: ProjectType
  projectUrl?: string
  notesVisibleToInstallers?: string
  requiredDocuments?: RequiredDocConfig[] // from the step configuration
  requiredDocsCount?: number              // number that's actually outstanding for the installer
  approvedDocsCount?: number
  docsNeedSupportActionCount?: number
  docsNeedInstallerActionCount?: number
  fundReleasesRequested?:FundReleaseRequest[]
  finalFundReleaseAmount?: number

  // only available for support users
  error?: string
  submissionResponse?: any
  envelopes?: { envelopeId: string, timestamp: Date }[]

  email?: string
  phone?: string
  updatedDate?: Date
  presubmitStatus?: PreSubmitStatus
  loanAmount?: number

  // V1 new fields
  productId: string // this might be a reference to the upcoming Product model
  monthlyAverageEnergy: number
  paydownAmount: number
  batteryCost: number
  treeRemovalCost: number
  adders?: Adder[]
  appId?: number

  constructor(o: Partial<StatusData>) {
    Object.keys(o).forEach(k => this[k] = o[k])

    this.lastStatusChange = new Date(this.lastStatusChange)
    this.createdDate = new Date(this.createdDate)
    this.updatedDate = new Date(this.updatedDate)

    this.uploads = this.uploads?.map(u => {
      u.timestamp = new Date(u.timestamp)
      return u
    }) || []

    this.uploads.forEach(u => u.documents?.forEach(doc => {
      doc.history?.forEach(h => {
        h.timestamp = new Date(h.timestamp)
      })
    }))

    this.fundReleasesRequested = this.fundReleasesRequested?.map(fr => {
      fr.requestedAt = new Date(fr.requestedAt)
      return fr
    }) || []
  }

  uiSort(): void {
    this.uploads.forEach(u => u.documents?.forEach(doc => {
      doc.history?.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf())
    }))
    this.uploads.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf())
  }
}

