import React, { useState, useRef } from 'react'
import { TextInputWithErrorProps, TextInputProps, dollars, dollarsToNum, extractDate, mmddyyyy, ValidatorFunction } from '@oneethos/shared'
import { Validate } from '@oneethos/shared'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
export * from './address-inputs'

export const ValidatedInput = ({
  value,
  onChange,
  onBlurFormatter,
  placeholder,
  validator,
  extraValidator,
  emptyOk,
  before,
  after,
  ...props
}: TextInputWithErrorProps) => {
  const [blurred, setBlurred] = useState(0)
  const ref = useRef<HTMLInputElement>(null)
  let error = ''

  if (blurred && document.activeElement !== ref.current) {
    if (validator) {
      error = (emptyOk && !value) ? '' : validator(value)
      // Only check extra validation if base validation passes
      if (!error && extraValidator) {
        error = extraValidator(value)
      }
    }
  }

  return <div className="validated-input">
    <div className="input-group">
      {before ? <span className="input-group-text">{before}</span> : null}
      <input
        {...props}
        type="text"
        ref={ref}
        className={`form-control ${error ? 'error' : ''}`}
        // increment blur count to trigger a re-render after blur to set error if invalid
        onBlur={ev => {
          setBlurred(b => b + 1)
          if (onBlurFormatter) {
            onChange(onBlurFormatter(value))
          }

          if (props.onBlur) {
            props.onBlur(ev)
          }
        }}
        value={value || ''}
        aria-label={placeholder || 'enter value'}
        placeholder={placeholder}
        onChange={ev => onChange(ev.target.value)}
      />
      {after ? <span className="input-group-text">{after}</span> : null}
    </div>
    {error ? <p className="small error">{error}</p> : null}
  </div>
}

export const TextInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={s => !s ? 'Required' : ''}
  />
}

export const DateInput = (props: TextInputProps & { validator?: (s: string) => string }) => {
  return (
    <ValidatedInput
      {...props}
      onChange={s => {
        let val = typeof s !== 'string' ? '' : s
        if (!/^[0-9]{1,2}(\/|\/[0-9]{1,2}|\/[0-9]{1,2}\/[0-9]{0,4})?$/.test(val)) {
          val = val.trim().replace(/[^0-9]/g, '')
          if (val.length > 2 && val.length < 5) {
            val = `${val.slice(0, 2)}/${val.slice(2)}`
          } else if (val.length >= 5) {
            val = `${val.slice(0, 2)}/${val.slice(2, 4)}/${val.slice(4, 8)}`
          }
        }
        props.onChange(val)
      }}
      onBlurFormatter={val => {
        const d = extractDate(val || '')
        return d && d.toString() !== 'Invalid Date' ? mmddyyyy(d) : val
      }}
      validator={props.validator}
      placeholder={'mm/dd/yyyy'}
    />
  )
}

export const DateOfBirthInput = (props: TextInputProps) => {
  return <DateInput {...props} validator={Validate.isValidDOB} />
}

export const LicenseExpirationDateInput = (props: TextInputProps) => {
  return <DateInput {...props} validator={Validate.isValidLicenseExpirationDate} />
}

export const LicenseIssueDateInput = (props: TextInputProps) => {
  return <DateInput {...props} validator={Validate.isValidLicenseIssueDate} />
}

export const EmailInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidEmail}
    placeholder={'example@domain.com'}
  />
}

export const PhoneInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidPhone}
    placeholder={'7191234567'}
  />
}

export const SocialInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidSocial}
    placeholder={'000-00-0000'}
  />
}

export const DollarsInput = (props: TextInputProps & { extraValidator?: ValidatorFunction }) => {
  return <ValidatedInput
    {...props}
    placeholder={'$10,000'}
    validator={Validate.isValidDollars}
    onChange={s => {
      // onChange is called after onBlurFormatter, so it has to respect the formatting 
      // from that function instead of just using dollars(dollarsToNum(val), 2); plus 
      // using that here causes input to lose cursor position
      const val = s?.trim().replace(/[^$0-9.,]/g, '')
      props.onChange(val)
    }}
    onBlurFormatter={val => {
      return val !== undefined ? dollars(dollarsToNum(val), 2) : undefined
    }}
  />
}

type DollarsNonZeroInputProps = TextInputProps & { extravalidationmessage?: string }

export const DollarsNonZeroInput = (props: DollarsNonZeroInputProps) => {
  return <ValidatedInput
    {...props}
    validator={(s) => Validate.checkDollarValue(s, props.extravalidationmessage)}
    onChange={s => {
      const val = s?.trim().replace(/[^$0-9.,]/g, '')
      props.onChange(val)
    }}
    onBlurFormatter={val => {
      return val !== undefined ? dollars(dollarsToNum(val), 2) : undefined
    }}
  />
}

type SignedDollarsInputProps = TextInputProps & { 
  extravalidationmessage?: string;
  isNegative?: boolean;
}

export const SignedDollarsInput = (props: SignedDollarsInputProps) => {
  const { extravalidationmessage, isNegative, ...restProps } = props
  
  return <ValidatedInput
    placeholder={'$10,000'}
    {...restProps}
    validator={(val) => {
      const valueWithoutSign = val?.replace(/^[+-]\s/, '')
      const isValid = Validate.isValidDollars(valueWithoutSign)
      if (!isValid && extravalidationmessage) {
        return extravalidationmessage
      }
      return isValid
    }}
    onChange={s => {
      const val = s?.trim().replace(/[^$0-9.,]/g, '')
      props.onChange(val)
    }}
    onBlurFormatter={val => {
      if (val === undefined) return undefined
      const numericValue = dollarsToNum(val)
      const sign = isNegative ? '-' : '+'
      return `${sign} ${dollars(Math.abs(numericValue), 2)}`
    }}
    value={props.value ? `${isNegative ? '- ' : '+ '}${props.value}` : props.value}
  />
}

export const ZipInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidZip}
    placeholder={'12345'}
  />
}

export const RoutingNumberInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isRoutingNumber}
    onChange={s => {
      const val = s.trim().replace(/[^0-9]/g, '')
      props.onChange(val)
    }}
  />
}

export const IntegerInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isInteger}
    onChange={s => {
      const val = s.trim().replace(/[^0-9]/g, '')
      props.onChange(val)
    }}
  />
}

export const NumericInput = (props: TextInputProps & { extraValidator?: ValidatorFunction }) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isNumeric}
    onChange={s => {
      const val = s.trim().replace(/[^0-9.]/g, '')
      props.onChange(val)
    }}
  />
}

type PasswordInputProps = {
  value?: string
  onChange: (s: string) => void
}

export const PasswordInput = (props: PasswordInputProps) => {
  const [show, setShow] = useState(false)

  return <div className="input-group">
    <input
      type={show ? 'text' : 'password'}
      className="form-control"
      value={props.value || ''}
      onChange={ev => props.onChange(ev.target.value)}
    />
    <span
      className="input-group-text"
      onClick={() => setShow(!show)}
    >
      {show ? <FaEye /> : <FaEyeSlash />}
    </span>
  </div>
}
