import React, { useState, useRef, useEffect } from 'react'
import { FaCity, FaStore, FaExternalLinkAlt } from "react-icons/fa"

type Item = {
  label: string
}

type DropDownProps = {
  items: Item[]
  dropdownOnly?: boolean // copied from ecountabl, unsure if needed
  onSelect: (item: Item) => void
  buttonText?: string
}

export const DropDownList = ({
  items,
  onSelect,
  buttonText,
  dropdownOnly,
}: DropDownProps) => {
  return (
    <ul className="list-group dd" style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
      {items.map((item, idx) => {
        return (
          <li
            key={idx}
            className="list-group-item"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: 10,
              // cursor: dropdownOnly ? 'pointer' : 'default'
            }}
            onClick={() => {
              onSelect(item)
            }}
          >
            <div style={{ marginRight: '1em', flex: 6 }}>
              {item.label}
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export type SearchProps = {
  query?: string
  onQueryChange?: (s: string) => void
  onSelect: (item: any) => void
  initialQuery?: string
  buttonText?: string
  autoSearch?: boolean
  autoFocus?: boolean
  hideNoResults?: boolean
  suggestions?: Item[]
  inputPrependChildren?: JSX.Element
  inputAppendChildren?: JSX.Element
  style?: React.CSSProperties
  placeholder?: string
  dropdownOnly?: boolean   // hides buttons and simplifies behavior
  clearAfterSelect?: boolean
  searching?: boolean
  onBlur?: () => void
}

export const SearchWithSuggestions = ({
  onSelect,
  query,
  buttonText,
  autoFocus = true,
  hideNoResults = false,
  inputPrependChildren,
  inputAppendChildren,
  onQueryChange,
  suggestions,
  placeholder,
  style,
  dropdownOnly,
  clearAfterSelect,
  searching,
  onBlur = null
}: SearchProps) => {
  const [showResults, setShowResults] = useState(false)

  const inputRef = useRef(null)
  const elRef = useRef(null)

  useEffect(() => {
    if (suggestions.length > 0) {
      setShowResults(true)
    }
  }, [suggestions.length])

  useEffect(() => {
    const handleClickOutside = ev => {
      if (elRef.current && !elRef.current.contains(ev.target)) {
        setShowResults(false)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const showNoResults = (!hideNoResults && !suggestions.length)

  return (
    <div className="search-sugg" style={style} ref={elRef}>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          style={showResults ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : null}
          value={query || ''}
          autoComplete="off"
          onFocus={() => {
            setShowResults(true)
          }}
          autoFocus={autoFocus}
          ref={inputRef}
          onChange={ev => {
            onQueryChange(ev.target.value)
          }}
          placeholder={placeholder || "Search"}
          onBlur={() => { 
            onBlur && onBlur()
          }}
        />

        {!inputAppendChildren ? null :
          <div className="input-group-append">
            <span className="input-group-text" id="basic-addon1">{inputAppendChildren}</span>
          </div>
        }
      </div>
      {(suggestions.length && showResults) ? (
        <DropDownList
          items={suggestions}
          dropdownOnly={dropdownOnly}
          onSelect={(item) => {
            onSelect(item)
            setShowResults(false)
          }}
          buttonText={buttonText}
        />
      ) : null}
      {/* {showNoResults ? <div>no companies found for search</div> : null} */}
    </div>
  )
}

export default SearchWithSuggestions