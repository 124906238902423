import { SubformProps } from "."
import { LoanApplication } from "@oneethos/shared"
import { SelectYesNo } from "../../components/forms"
import { FormInput } from "../"

export const Spouses = ({ formData, update }: Partial<SubformProps>) => {
  return <>
    <div className="alert alert-secondary">
      Some states require spouses to consent to certain types of
      loans, even if they are not a borrower.
    </div>
    <div className="row align-items-end justify-content-stretch">
      {formData.includeCoBorrower === 'yes' ? (
        <div className="form-group col">
          <label>Is your Co-Borrower also your spouse?</label>
          <SelectYesNo
            value={formData.isCoborrowerSpouse || ''}
            onChange={val => {
              const data: Partial<LoanApplication> = {
                ...formData,
                isCoborrowerSpouse: val
              }

              if (val === 'yes') {
                data.hasSpouse = 'yes'
                data.spouseFirstName = formData.coFirstName
                data.spouseLastName = formData.coLastName
                data.spouseEmail = formData.coEmail
              } else {
                data.hasSpouse = ''
                data.spouseFirstName = undefined
                data.spouseLastName = undefined
                data.spouseEmail = undefined
              }

              update(data)
            }}
          />
        </div>
      ) : null}
      {formData.includeCoBorrower === 'no' || formData.isCoborrowerSpouse === 'no' ? (
        <div className="form-group col-md-6">
          <label>Do you have a spouse?</label>
          <SelectYesNo
            value={formData.hasSpouse || ''}
            onChange={val => update({
              ...formData,
              hasSpouse: val,
              spouseFirstName: undefined,
              spouseLastName: undefined,
              spouseEmail: undefined
            })}
          />
        </div>
      ) : null}
    </div>
    {formData.hasSpouse === 'yes' && formData.isCoborrowerSpouse !== 'yes' ? (
      <>
        <div className="row">
          <div className="col-md-6">
            <FormInput
              field='spouseFirstName'
              value={formData.spouseFirstName}
              onChange={s => update('spouseFirstName', s)}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              field='spouseLastName'
              value={formData.spouseLastName}
              onChange={s => update('spouseLastName', s)}
            />
          </div>
        </div>
        <FormInput
          field='spouseEmail'
          value={formData.spouseEmail}
          onChange={s => update('spouseEmail', s)}
        />
      </>
    ) : null}
  </>
}

export default Spouses
