import { useEffect, useState } from "react"
import { Section } from "../components"
import api from "../api-client"
import { toast } from "react-toastify"
import './credit-report.scss'
import { errorToString } from "@oneethos/shared"
import { Spinner, SpinnerSize } from "@fluentui/react"
import { useSearchParams } from "react-router-dom"
import EquifaxDitReport from "../components/equifax-dit-report"
import { CreditReportTester } from "../credit-report-tester"
import { CreditReportItem, jsonToCreditItem } from "../credit-report-item"

export const CreditReports = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [searchParams] = useSearchParams()

  const doSearch = (params: URLSearchParams) => {
    setLoading(true)
    api.get(`/credit-reports?${params.toString()}`).then(res => {
      const _list = res.list.map(i => i?.reportType === 'dit' ? { report: i, item: i } : jsonToCreditItem(i))

      setList(_list)
    }).catch(ex => toast.error(errorToString(ex))).finally(() => setLoading(false))
  }

  useEffect(() => {
    const obj = Object.fromEntries(searchParams)
    if (Object.keys(obj).length) {
      doSearch(searchParams)
    }
  }, [searchParams])

  return (
    <div>
      <div className="no-print">
        <h3>Credit Reports</h3>
        <div className="alert alert-info">Prequal reports are deleted after 30 days</div>
        <div className="form-group">
          <label>Search by Name</label>
          <input
            type="text"
            className="form-control"
            value={search}
            onChange={ev => setSearch(ev.target.value)} />
          <div className="text-small">must match first or last name exactly and is case-sensitive</div>
        </div>
        <div className="buttons mb-3">
          <button type="button" className="btn btn-primary" onClick={() => doSearch(new URLSearchParams({ search }))}>
            Search
          </button>
        </div>
      </div>
      <div className="list-group">
        {loading ? <Spinner size={SpinnerSize.large} /> : <>
          {list.length === 0 && <div className="alert alert-secondary">No reports match your search</div>}
          {list.map((entry, i) => {
            const isPrequal = ['prequal', 'prequal-combined'].includes(entry.item.reportType)
            return isPrequal ? <CreditReportItem key={i}
              report={entry.report}
              item={entry.item}
            /> : <EquifaxDitReport item={entry.item} />
          })}
        </>
        }
      </div>
    </div>
  )
}

export const CreditReportsPage = () => {
  return <div>
    <Section>
      <CreditReports />
    </Section>
    <div className="no-print">
      <Section>
        <CreditReportTester />
      </Section>
    </div>
  </div>
}

export default CreditReportsPage