import { IProduct, dollars, AmortizationSchedule } from "@oneethos/shared"
import { IEquipmentCard } from "./equipments"
import LoanDetailLogos from "./loan-detail-logos"
// import PayoffScenarios from "./payoff-scenarios"
// import LoanProductInfo from "./loan-product-info"

type LoanDetailsProps = {
  product: IProduct
  formData: any
  financingTenant: any
  incentive: any
  rateEffectiveDate: string
  loanPlusClosingCosts: number
  isResidential: boolean
  loanTermMonths: number
  isOrgTeslaCertified: boolean
  selectedEquipment: IEquipmentCard
  amortizationAutoPay: AmortizationSchedule
  amortizationNoAutoPay: AmortizationSchedule
}

const LoanDetails = ({
  product,
  formData,
  financingTenant,
  incentive,
  rateEffectiveDate,
  loanPlusClosingCosts,
  isResidential,
  loanTermMonths,
  isOrgTeslaCertified,
  selectedEquipment,
  amortizationAutoPay,
  amortizationNoAutoPay
}: LoanDetailsProps) => {
  const loanTermYears = (product?.term / 12).toFixed(1)
  const interestRate = (product, withAutopay = true) =>
    withAutopay ? product?.interestRateWithAutopay : product?.interestRateWithoutAutopay

  const estimatedPaymentWithAutopay = dollars(amortizationAutoPay?.payment)
  const estimatedPaymentWithoutAutopay = dollars(amortizationNoAutoPay?.payment)

  return (
    <>
      <LoanDetailLogos
        financingTenant={financingTenant}
        incentive={incentive}
        isOrgTeslaCertified={isOrgTeslaCertified}
        selectedEquipment={selectedEquipment}
        isResidential={isResidential}
      />
      <section className="list-item">
        {rateEffectiveDate && (
          <p className="fw-bold text-black">
            Please note that OUR RATES have changed effective {rateEffectiveDate}.
          </p>
        )}
        {product && (
          <>
            <h6 className="heading-7">{loanTermYears} year term<br /></h6>
            <h6 className="heading-7">
              {interestRate(product)}%
              {isResidential ? '(with Auto Pay)' : null}
              <br />
            </h6>
            <h6 className="heading-7">No Dealer Fee<br /></h6>

            {formData?.projectType === 'residential' && (
              <p className="text-secondary-emphasis">
                {financingTenant.name} offers a discount to clients that opt into, and maintain,
                automatic monthly payments. The bill amount will automatically be withdrawn
                from the clients checking account.
              </p>
            )}
          </>
        )}
        <div className="oe-pmt-summary">
          <div className="text-block-4">Estimated Payment:</div>
          <div className="text-block-3" style={{ color: 'var(--neutral--700)' }}>
            {isResidential ? estimatedPaymentWithAutopay : estimatedPaymentWithoutAutopay}
          </div>

          {isResidential ? <div>
            <>
              <div className="text-block-4">w/o Auto Pay:</div>
              <div className="text-block-3" style={{ color: 'var(--neutral--700)' }}>
                {estimatedPaymentWithoutAutopay}
              </div>
            </>
          </div> : null}
        </div>
      </section>
    </>
  )
}

export default LoanDetails

