import { useState } from "react"
import { EquifaxTrade, dollars } from "@oneethos/shared"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"
import AccountReport from "./account-report"

const TradesByType = ({ trades }) => {
  const summary = {}

  trades.forEach(t => {
    let code = t.portfolioTypeCode?.code
    if (t.rawNarrativeCodes?.length) {
      for (const narrative of t.rawNarrativeCodes) {
        // codes that appear to considered mortgages
        if (['EF', 'EC'].includes(narrative)) {
          code = 'M'
          break
        }
      }
    }

    const val = summary[code] || 0
    if (
      // ignore revolving if authorized user
      (t.accountDesignator?.code === 'A' && code === 'R') ||
      // or if no balance
      !t.balance
    ) {
      return
    }

    summary[code] = val + (t.scheduledPaymentAmount || 0)
  })

  const categories = [
    { key: 'M', label: 'Mortgage' },
    { key: 'R', label: 'Revolving' },
    { key: 'I', label: 'Installment' },
    { key: 'C', label: 'Credit Line' },
    { key: 'O', label: 'Other' }
  ]

  const total = Object.values(summary).reduce((prev: number, cur: number) =>
    prev + cur, 0) as number

  return (
    <div className="col-md-4 my-2">
      {categories.map(({ key, label }) => (
        <div key={key} className="d-flex justify-content-between">
          <span className="py-1">{label}:</span>
          <span>{dollars(summary[key] || 0)}</span>
        </div>
      ))}
      <div className="d-flex justify-content-between border-top border-dark mt-2">
        <span className="py-2">Total:</span>
        <span className="py-2">{dollars(total)}</span>
      </div>
    </div>
  )
}

const TradesTable = ({ trades, title }) => {
  return (
    <>
      <div className="mt-3">
        <div className={`bg-secondary text-light p-2`}>{title}</div>
        <table className="table">
          <tbody>
            {trades?.map((trade: EquifaxTrade, idx) => (
              <tr key={idx}>
                <td>
                  <AccountReport trade={trade} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

const Trades = ({ trades }) => {
  const [expand, setExpand] = useState(true)

  if (trades.length === 0) {
    return (
      <div className="fs-5 mb-2">
        <div>Trades (0)</div>
      </div>
    )
  }

  const sharedTrades = trades.filter(t => t.isSharedWithCoapplicant)
  const ownTrades = trades.filter(t => !t.isSharedWithCoapplicant)

  return <div className="trades mb-2">
    <div className="section-hdr" onClick={() => setExpand(!expand)}>
      <span className="fs-5">Trades ({trades?.length || 0})</span>
      {expand ?
        <FaChevronDown size='15' /> :
        <FaChevronRight size='15' />
      }
    </div>
    {expand && trades?.length ? <>
      <TradesByType trades={trades} />
      {sharedTrades?.length ? <>
        <TradesTable trades={sharedTrades} title="Trades Shared with Co-Applicant" />
      </> : null}
      <TradesTable trades={ownTrades} title="Personal Trades" />
    </> : null}
  </div >
}

export default Trades