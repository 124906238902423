import { useEffect, useState } from 'react'
import api from '../api-client'
import { toast } from 'react-toastify'
import { FaEdit, FaTrash } from "react-icons/fa"
import { dollars } from '@oneethos/shared'
import { scrollToTop } from '../lib'
import { useAppState } from '../hooks'
import TenantProductForm from './tenant-product-form'

const TenantProductList = () => {
  const { tenant: { config: tenant } } = useAppState()
  const [list, setList] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [isDisplayingList, setIsDisplayingList] = useState(true)
  const [productID, setProductID] = useState<string>('')
  const tenantID = tenant?._id

  useEffect(() => { 
    fetchProducts()
  }, [])

  const fetchProducts = () => {
    api.get(`/tenant/${tenantID}/products`)
      .then(response => {
        setList([...response])
      })
      .catch(error => {
        toast.error(`Error fetching products: ${error.message}`)
      })
  }

  const handleCreateProduct = (newProduct) => {
    setList(prevList => [...prevList, newProduct])
    onList()
  }

  const handleDeleteProduct = (productId: string) => {
    api.delete(`/tenant/${tenantID}/products/${productId}`)
      .then(() => {
        setList(list.filter(product => product.id !== productId))
        toast.success('Product deleted')  
      })  
      .catch(error => {
        toast.error(`Error deleting product: ${error.message}`)
      }) 
  }
  
  const handleEditProduct = (updatedProduct) => {
    setList(prevList => 
      prevList.map(product => product.id === updatedProduct.id ? updatedProduct : product))
    onList()
  }

  const onEdit = (productId: string) => {
    setIsEditing(!isEditing)
    setIsDisplayingList(!isDisplayingList)
    setProductID(productId)
  }

  const onAdd = () => {
    setIsAdding(!isAdding)
    setIsDisplayingList(!isDisplayingList)
  }
  
  const onList = () => {
    setIsDisplayingList(!isDisplayingList)
    setIsEditing(false)
    setIsAdding(false)
    scrollToTop()
  }

  return (
    <>
      {isAdding && <TenantProductForm onSave={handleCreateProduct} tenantID={tenantID} />}
      {isEditing && 
        <TenantProductForm productID={productID} onEdit={handleEditProduct} tenantID={tenantID} />
      }
      {list.length > 0 && isDisplayingList && 
    <>
      <div className="table-list-wrap">
        <table className="table">
          <tbody>
            <tr className='text-center'>
              <th className='text-start'>Product Type</th>
              <th>APR</th>
              <th>Interest with Autopay</th>
              <th>Interest without Autopay</th>
              <th>Term</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            {list.map(product => 
              <tr key={product.id} className='text-center'>
                <td className='my-3 text-capitalize text-start'>{product.productType}</td>
                <td>{product.apr}%</td>
                <td>{product.interestRateWithAutopay}%</td>
                <td>{product.interestRateWithoutAutopay}%</td>
                <td>{product.term}</td>
                <td>
                  <FaEdit style={{ cursor:'pointer'}} color='gray' onClick={() => onEdit(product.id)} />
                </td>
                <td>
                  <FaTrash style={{cursor: 'pointer'}} color='gray' onClick={() => handleDeleteProduct(product.id)} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>}
      {isDisplayingList && <button type="button" className="btn btn-secondary btn-sm" onClick={onAdd}>Add a product</button>} 
      {!isDisplayingList && 
      <div className="buttons mt-3">
        <button type="button" className="btn btn-secondary btn-sm" onClick={onList}>List of products</button>
      </div>
      }
    </>
  )
}

export default TenantProductList
