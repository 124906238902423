import { Fragment, useState } from 'react'
import { CriteriaEvaluationResult, CriteriaSpec, yyyymmdd } from "@oneethos/shared"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6"

type EvaluationProps = {
  evaluation: CriteriaEvaluationResult
}

const CriteriaCell = ({ spec }: { spec: CriteriaSpec }) => {
  return (
    <td>
      {Object.entries(spec).map(([key, value]) =>
        <div key={key}>
          {key}:&nbsp;
          <b>{typeof value.getDay === 'function' ? yyyymmdd(value as Date) : value.toString()}</b>
        </div>)
      }
    </td>
  )
}

const Evaluation = ({ evaluation }: EvaluationProps) => {
  const [expand, setExpand] = useState(true)

  const passed = evaluation?.byCriteria.filter(r => r.result === 'pass')

  if (!evaluation) {
    return <div className="alert alert-danger">
      No evaluation available
    </div>
  }

  return (
    <div className="eval my-2">
      <div className="pointer">
        {passed.length
          ? <div className="badge fs-6 mb-2 text-bg-success" onClick={() => setExpand(!expand)}>
            Approved&nbsp;({passed.length})
            {expand ? <FaChevronDown /> : <FaChevronRight />}
          </div>
          : <div className="badge fs-6 mb-2 text-bg-danger" onClick={() => setExpand(!expand)}>
            Declined&nbsp;
            {expand ? <FaChevronDown /> : <FaChevronRight />}
          </div>}
      </div>
      {expand ?
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Result</th>
                <th>Min</th>
                <th>DTI</th>
                <th>Bankruptcy</th>
                <th>Collections</th>
                <th>Past Due</th>
              </tr>
            </thead>
            <tbody>
              {evaluation.byCriteria.map((res, i) => <Fragment key={i}>
                <tr>
                  <td>{res.result === 'pass' ? <FaCircleCheck color='green' /> : <FaCircleXmark color='red' />}</td>
                  <td>{res.criteria.minScore}</td>
                  <td>{res.criteria.debtToIncome}</td>
                  <CriteriaCell spec={res.criteria.bankruptcy} />
                  <CriteriaCell spec={res.criteria.collections} />
                  <CriteriaCell spec={res.criteria.pastDue} />
                </tr>
                {res.result === 'fail' ? <tr>
                  <td colSpan={7} >
                    <div className='mb-2'>Reasons:</div>
                    {res.reasons.map((reason, j) => <div key={j}>{reason}</div>)}
                  </td>
                </tr> : null}
              </Fragment>)}
            </tbody>
          </table>
        </div> : null}
    </div>)
}

export default Evaluation